import { CloseButton, Modal } from "react-bootstrap";
import { LoadError } from "../hooks/useLoadError";
import { ErrorIndicator } from "./ErrorIndicator";
import { LoadingIndicator } from "./LoadingIndicator";

interface Props {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>,
    title?: string;
    loadError?: LoadError;
    className?: string;
    children: React.ReactNode;
}

export function CustomModal(props: Props) {
    if (props.loadError) {
        return (
            <Modal show={props.show} onShow={() => props.setShow(true)} onHide={() => props.setShow(false)} centered contentClassName="modal-dark" data-bs-theme="dark" fullscreen="sm-down">
                <Modal.Body>
                    <div className="forgot-password-text">  <CloseButton className="float-right btn-close-white" onClick={() => props.setShow(false)} /></div>
                    <div className="row align-items-center" style={{ minHeight: "85%" }}>
                        <div>
                            {props.title && <h3 className="block-title">{props.title}</h3>}
                            {props.loadError.loading && <LoadingIndicator />}
                            {!props.loadError.loading && props.loadError.error !== undefined &&
                                <>
                                <ErrorIndicator errorText={props.loadError.error} />
                                <div className="text-center">
                                    <button type="submit" className="btn scale-on-hover" onClick={() => props.loadError?.setError(undefined)}>Back</button>
                                </div>
                                </>
                            }
                            {!props.loadError.loading && props.loadError.error === undefined && props.children}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    } else {
        return (
            <Modal show={props.show} onShow={() => props.setShow(true)} onHide={() => props.setShow(false)} centered contentClassName="modal-dark" data-bs-theme="dark" fullscreen="sm-down">
                <Modal.Body>
                    <div className="forgot-password-text">  <CloseButton className="float-right btn-close-white" onClick={() => props.setShow(false)} /></div>
                    <div className="row align-items-center" style={{ minHeight: "85%" }}>
                        <div>
                            {props.title && <h3 className="block-title">{props.title}</h3>}
                            {props.children}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}