import { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import { UniversalModal } from './UniversalModal';

export function showConfirmationModal(modal: UniversalModal | null, title: string, yes: string, content: ReactNode, onConfirm: () => void) {
    modal?.show(<>
        <h3 className="block-title">{title}</h3>
        {content}
        <div className="d-flex justify-content-end mt-2">
            <Button variant="light" onClick={() => modal.hide()}>Cancel</Button>
            <Button variant="danger" className="ms-2" onClick={() => { onConfirm(); modal.hide(); }}>{yes}</Button>
        </div>
    </>);
}