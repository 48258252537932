export interface ItemData {
    id: string;
    type: ItemType;
    rarity: ItemRarity;
    name: number;
    limited: boolean;
    limitationDate: Date;
}

export enum ItemType {
    Case,
    Avatar,
    Frame,
    BaseSkin,
    UnitSkin,
}

export enum ItemRarity {
    Common,
    Rare,
    Epic,
    Legend,
    Cosmic,
}

export interface InventoryItem {
    itemData: ItemData;
    count: number;
}