import React, { useCallback, useState } from "react";
import moment from 'moment';
import { Box } from "../components/Box";
import { ErrorIndicator } from "../components/ErrorIndicator";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { ViewLogComponent } from "../components/ViewLogComponent";
import { useLogs } from "../hooks/useLogs";
import { isNullOrEmpty } from "../utils/commonUtils";
import { DatePickerRange } from "../components/DatePickerRange";
import { RangeKeyDict, Range} from "react-date-range";


export function ViewLogsPage() {
    const [selectedStartDate, setSelectedStartDate] = useState<moment.Moment>(moment.utc().startOf('day'));
    const [selectedEndDate, setSelectedEndDate] = useState<moment.Moment>(moment.utc());

    const [valueDateRangePicker, setValueDateRangePicker] = useState<Range[] | undefined>([
        {
            startDate: moment().toDate(),
            endDate: moment().toDate(),
            key: "selection",
        },
    ]);

    const handleChangeValueDateRangePicker = useCallback((ranges: RangeKeyDict) => {
        const { selection } = ranges;

        if (selection) {
            const { startDate, endDate } = selection;

            const startLocalMoment = moment(startDate);
            const endLocalMoment = moment(endDate).endOf('day');

            const startMoment = startLocalMoment.utc();
            const endMoment = endLocalMoment.utc();

            setValueDateRangePicker([selection]);

            setSelectedStartDate(startMoment);
            setSelectedEndDate(endMoment);
        }
    }, []);

    const logs = useLogs(selectedStartDate, selectedEndDate);
    const [logMessageSelector, setLogMessageSelector] = useState('');

    const filteredItems = logs?.logs.filter(message => {
        const isMessageName = message.message.toLowerCase().includes(logMessageSelector.toLowerCase());
        return isMessageName;
    });

    const [openDataPicker, setOpenDataPicker] = useState<boolean>(false);

    return (
        <>
            <Box title="Server Logs">
                <input
                    className="form-control"
                    type="text"
                    placeholder="Log message"
                    value={logMessageSelector}
                    onChange={(e) => setLogMessageSelector(e.target.value)}
                />
                <button className="btn mt-2 w-100" onClick={() => (setOpenDataPicker(!openDataPicker))}>
                    Data picker
                </button>
                {openDataPicker && 
                    <div className="mt-2">
                        <DatePickerRange
                            onChange={handleChangeValueDateRangePicker}
                            ranges={valueDateRangePicker}
                        />
                    </div>
                }
                {
                    <div className="mt-3">
                        {logs.loading && <LoadingIndicator />}
                        {!logs.loading && !isNullOrEmpty(logs.error) && <ErrorIndicator errorText={logs.error} />}
                        {filteredItems?.reverse().map((value) => <ViewLogComponent log={value} key={value.id} />)}
                        {filteredItems.length == 0 && <>No Logs with this filters</>}
                    </div>
                }
            </Box>
        </>
    );
}
