import moment from 'moment';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Log, LogType } from "../models/Log";
import {BoxMini } from "./Box";

interface Props {
    log: Log,
}

function getColorByLogType(type: LogType) {
    switch (type) {
        case LogType.Info:
            return "logs-bg-info";
        case LogType.Warning:
            return "bg-yellow";
        case LogType.Error:
            return "logs-bg-eror";
        default:
            return "";
    }
}

export function ViewLogComponent({log}: Props) {

    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <BoxMini colorClass={`mb-2 ${getColorByLogType(log.type)}`}>
                <div className="row">
                    <div className="col-auto">
                        <div >
                            {log.author &&
                                <Link to={"/player/" + log.author.id} >
                                    <span className="link-opacity-100 logs-blue-text">
                                        {log.author.nickname + ".  "}
                                    </span>
                                </Link>
                            }
                            {
                                !isOpen ?
                                    <span> {log.message.substring(0, 80) + (log.message.length > 80 ? "..." : "")} </span> :
                                    <span>{log.message}</span>
                            }
                        </div>
                        <div className="d-flex">
                            {log.tags && log.tags.map((value) =>
                                <BoxMini colorClass="logs-bg-tag p-1 me-1 mt-2 mb-1">
                                    <span>{value}</span>
                                </BoxMini>)
                            }
                        </div>
                    </div>
                    <div className="col d-flex align-items-center justify-content-end">
                        {
                            !isOpen ?
                                <img src={"/images/icons/down_arrow.png"} style={{ width: "20px", cursor: "pointer" }} onClick={handleToggle} /> :
                                <img src={"/images/icons/down_arrow.png"} style={{ width: "20px", cursor: "pointer", transform:"rotate(180deg)"}} onClick={handleToggle} /> 
                        }
                    </div>
                </div> 
                {isOpen && (
                    <div>
                        {log.customMetadata &&
                            <div className="w-100">
                                <span style={{ color: "#ECDDAA"}}>Metadata:</span>
                            {Object.entries(log.customMetadata).map(([key, value]) =>
                                <div className="d-flex text-danger mb-1 wrap-flex" key={key}>
                                    <span style={{ color:"#ECDDAA"}}>{"●  " + key} :</span>
                                    <span className="ms-1" style={{ color: "#ECDDAA" }}> {value}</span>
                                </div>
                            )}
                            </div>
                        }
                        <span style={{color:"#808080"}}>{moment.utc(log.date).local().format("DD:MM:YYYY  HH:mm")}</span>
                    </div>
                )}
            </BoxMini>
        </>
    )
}