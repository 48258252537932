export interface PrivateProfile extends PublicProfile {
    xp: number;
    cryCount: number;
    isBlocked: boolean;
}

export interface PublicProfile extends ProfileBadge
{
    premiumExpirationDate: Date;
    techTreeTier: TechTreeTier;
    level: number;
    lastTimeOnline: Date;
}

export interface ProfileBadge
{
    id: number;
    accessLevel: AccessLevel;
    nickname: string;
    avatarId: string;
    frameId: string;
    online: boolean;
}

export enum AccessLevel {
    Player = 0,
    Moder = 1,
    Admin = 2,
    FullAccess = 3,
}

export enum TechTreeTier {
    Tier1 = 0,
    Tier2 = 1,
    Tier3 = 2,
    Tier4 = 3,
}