import React from 'react';
import { ItemData, ItemRarity } from '../models/ContentSystem';
import { getItemImageUrl } from '../utils/commonUtils';


interface Props {
    itemData: ItemData,
    children?: React.ReactNode,
    className?: string,
}

function getColorByRarity(rarity: ItemRarity) {
    switch (rarity) {
        case ItemRarity.Rare:
            return "#4E99E5";
        case ItemRarity.Epic:
            return "#D840FF";
        case ItemRarity.Legend:
            return "#FFBF1C";
        case ItemRarity.Cosmic:
            return "#FF005E";
        default:
            return "#585858";
    }
}

export function GameItemComponent(props: Props) {

    return (
        <>
            <div className="row">
                <div className={"col-auto " + props.className}>
                    <img className="col rounded" style={{ width: "64px" }} src={getItemImageUrl(props.itemData.id)} />
                    <img className="col ms-3" style={{ width: "24px" }} src={"/images/icons/item-types/" + props.itemData.type + ".png"}/>
                    <span className="col ms-3 align-items-center" style={{ color: getColorByRarity(props.itemData.rarity) }}>{props.itemData.id}</span>
                </div>
                <div className="col d-flex align-items-center justify-content-end">
                    {props.children}
                </div>
            </div>

        </>
    );
}
