import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../App';
import { ProfileBadge } from '../models/Profile';
import { getItemImageUrl } from '../utils/commonUtils';

interface Props {
    profile: ProfileBadge | undefined,
    className?: string,
    size?: number,
}

export function CircularAvatar({ profile, className, size }: Props) {
    const auth = useContext(AuthContext);

    if (size === undefined) {
        size = 44;
    }

    className = "rounded-circle " + className;

    if (profile === undefined) {
        return (
            <img src="/images/avatar-placeholder.png" className={className} height={size} alt="Avatar" />
        );
    }

    return (
        <Link className="" to={"/player/" + profile.id} style={{ width: size, height: size }}>
            <div className="img-container">
                <img src={getItemImageUrl(profile.avatarId)} className={className} height={size} alt="Avatar" />
                {(profile.online || profile.id === auth?.myProfile?.id) && <img src="/images/greencircle.png" style={{ position: "absolute", bottom: "2px", right: "2px" }} height="10px" alt="Online" />}
            </div>
        </Link>
    );
}