import { ProfileBadge } from "./Profile";

export interface Log {
    id: number;
    type: LogType;
    message: string;
    date: Date;
    author: ProfileBadge;
    tags?: string[];
    customMetadata?: { [key: string]: string };
}

export enum LogType {
    Info = 0,
    Warning = 1,
    Error = 2,
}