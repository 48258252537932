import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { AuthContext, UniversalModalContext } from '../App';
import { BigProfileViewer } from "../components/BigProfileViewer";
import { Box } from "../components/Box";
import { showConfirmationModal } from '../components/ConfirmationModal';
import { ErrorIndicator } from '../components/ErrorIndicator';
import { GameItemComponent } from '../components/GameItemComponent';
import { GameItemsViewer } from "../components/GameItemsViewer";
import { LoadingIndicator } from '../components/LoadingIndicator';
import { ProfileTagsViewer } from "../components/ProfileTagsViewer";
import { useOtherInventory } from '../hooks/useOtherInventory';
import { usePublicProfile } from "../hooks/usePublicProfile";
import { InventoryItem } from '../models/ContentSystem';
import { isNullOrEmpty, isPlayerId } from '../utils/commonUtils';

export function InventoryManagement() {

    const auth = useContext(AuthContext);

    const { id } = useParams()

    const profile = usePublicProfile(id != undefined ? parseInt(id) : 0);
    const otherInventory = useOtherInventory(id != undefined ? parseInt(id) : 0);

    const modal = useContext(UniversalModalContext);

    const modifyInventoryItemQuantity = async (playerId: number | undefined, item: InventoryItem | undefined, quantityChange: number | undefined) => {
        if (!playerId || auth == null || !item || quantityChange === undefined) return;
        const api = await auth.getApi();
        const response = await api.post("administration/ModifyInventoryItemQuantity", null, { params: { playerId, itemId: item.itemData.id, quantityChange } });
        if (response.status === 200) {
            item.count += quantityChange;
            if (!otherInventory.inventoryItems?.includes(item)) otherInventory.inventoryItems?.push(item);
        }
    }

    const removeAllItems = async (playerId: number | undefined) => {
        if (!playerId || auth == null) return;
        const api = await auth.getApi();
        await api.post("administration/RemoveAllItemsFromInventory", null, { params: { playerId } });
        await otherInventory.forseUpdate();
    }

    const addAllItems = async (playerId: number | undefined) => {
        if (!playerId || auth == null) return;
        const api = await auth.getApi();
        await api.post("administration/GrantAllItemsToPlayer", null, { params: { playerId } });
        await otherInventory.forseUpdate();
    }

    if (!isPlayerId(id)) {
        return (
            <Box title="Invalid Player ID" colorClass="bg-red">
                ◉︵◉
            </Box>
        );
    }

    return (
        <>
            <Box title="Profile">
                <BigProfileViewer profileBadge={profile.profile}>
                    <ProfileTagsViewer profile={profile.profile}/>
                </BigProfileViewer>
            </Box>
            {otherInventory.loading && <LoadingIndicator />}
            {!otherInventory.loading && !isNullOrEmpty(otherInventory.error) && <ErrorIndicator errorText={otherInventory.error} />}
            
            {!otherInventory.loading && isNullOrEmpty(otherInventory.error) &&
                <Box title={profile.profile?.nickname + "`s INVENTORY"}>
                    <GameItemsViewer listItemEntry={item =>
                    {
                        let curInventoryItem: InventoryItem | undefined;
                        let className: string = "";
                        otherInventory.inventoryItems?.forEach(inventoryItem => {
                            if (item.id === inventoryItem.itemData.id) {
                                curInventoryItem = inventoryItem;
                            }
                        });

                        if (curInventoryItem?.count === undefined || curInventoryItem.count === 0) {
                            className = "opacity-25";
                            curInventoryItem = curInventoryItem || ({} as InventoryItem);
                            curInventoryItem.itemData = item;
                            curInventoryItem.count = 0;

                        }

                        return (
                            <GameItemComponent key={item.id} itemData={item} className={className + " mb-2"}>
                                <Button className="me-2 btn-danger" onClick={() => modifyInventoryItemQuantity(profile.profile?.id, curInventoryItem, -5)}>-5</Button>
                                <Button className="me-2 btn-danger" onClick={() => modifyInventoryItemQuantity(profile.profile?.id, curInventoryItem, -1)}>-1</Button>
                                <span className="me-2 profile-tag text-align-center" style={{ backgroundColor: "#505050", width: "60px" }}>{curInventoryItem ? curInventoryItem.count : 0}</span>
                                <Button className="me-2" onClick={() => modifyInventoryItemQuantity(profile.profile?.id, curInventoryItem, +1)}>+1</Button>
                                <Button onClick={() => modifyInventoryItemQuantity(profile.profile?.id, curInventoryItem, +5)}>+5</Button>
                            </GameItemComponent>
                        );
                    }
                    }    
                />
            </Box>}
            <Box title="DANGER ZONE" colorClass="bg-red">
                <Button className="btn-danger mb-2 w-100" onClick={() => showConfirmationModal(modal, "Remove items", "Remove",
                    <>
                        {" ⚰️ Send request to remove ⚰️?"}
                    </>, () => removeAllItems(profile.profile?.id))}>
                        {" 😈 REMOVE ALL ITEMS FROM " + profile.profile?.nickname + " 😈 "}
                </Button>
                <Button className="btn-primary mb-2 w-100" onClick={() => showConfirmationModal(modal, "Add all items", "Add",
                    <> 
                        {" 😘 Send request to add 😘? "}
                    </>, () => addAllItems(profile.profile?.id))}>
                    {" 😇 ADD ALL ITEMS FOR " + profile.profile?.nickname + " 😇 "}
                </Button>
            </Box>
        </>
    )
}
