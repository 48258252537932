import { Box } from "../components/Box";
import { GameItemComponent } from "../components/GameItemComponent";
import { GameItemsViewer } from "../components/GameItemsViewer";

export function GameItemsPage() {

    return (
        <Box title="Game items">
            <GameItemsViewer listItemEntry={item => <GameItemComponent className="mb-2" key={item.id} itemData={item} />} />
        </Box>
    )
}