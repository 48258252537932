import { Auth } from "../hooks/useAuth";

export const sendFriendRequest = async (friendId: number | undefined, auth: Auth | null) => {
    if (!friendId || !auth) return;
    const api = await auth.getApi();
    await api.post("friends/SendFriendRequest", null, { params: { friendId } });
}

export const acceptFriend = async (friendId: number | undefined, auth: Auth | null) => {
    if (!friendId || !auth) return;
    const api = await auth.getApi();
    await api.post("friends/AcceptFriendRequest", null, { params: { friendId } });
}

export const removeFriend = async (friendId: number | undefined, auth: Auth | null) => {
    if (!friendId || !auth) return;
    const api = await auth.getApi();
    await api.post("friends/RemoveFriend", null, { params: { friendId } });
}