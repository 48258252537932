import { HubConnectionBuilder } from "@microsoft/signalr";
import { stat } from "fs";
import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { AuthContext } from "../App";
import { API_URL } from "../Global";
import { Auth } from "../hooks/useAuth";
import { PlayerNotification } from "../models/Notification";

export enum SystemConnectionState {
    NotAuthorized,
    Loading,
    Connected,
    Error,
    Reconnecting
}

export interface SystemHubContext {
    connectionState: SystemConnectionState;
    connectionError: string | undefined;
    currentOnline: number | undefined;
    notificationQueue: PlayerNotification[];
    setNotificationQueue: React.Dispatch<React.SetStateAction<PlayerNotification[]>>;
}

export function useSystemHubContext(auth: Auth): SystemHubContext  {
    const [cookies, setCookie] = useCookies();
    const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
    const [state, setState] = useState<SystemConnectionState>(SystemConnectionState.Loading);
    const [error, setError] = useState<string>();

    const [notificationQueue, setNotificationQueue] = useState<PlayerNotification[]>([]);
    const [currentOnline, setCurrentOnline] = useState<number>();

    // Create connection
    useEffect(() => {
        if (!auth?.authenticated) {
            setState(SystemConnectionState.NotAuthorized);

            if (connection) {
                connection.stop();
            }

            return;
        }

        const newConnection = new HubConnectionBuilder()
            .withUrl(API_URL + "hubs/system", {
                accessTokenFactory: async () => {
                    const token = await auth.getAccessToken();
                    return token ?? "";
                },
            })
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, [auth?.authenticated]);

    // Setup and Start connection
    useEffect(() => {
        if (connection && state !== SystemConnectionState.Connected) {
            connection.start().then(() => {
                setState(SystemConnectionState.Connected);
                console.log("System: Connected!");
            }).catch((e) => {
                setState(SystemConnectionState.Error);
                setError(e.message);
            });

            connection.onreconnecting((e) => {
                setState(SystemConnectionState.Reconnecting);
                setError(e?.cause + ". " + e?.message + " Reconnecting...");
            });

            connection.onreconnected(() => {
                setState(SystemConnectionState.Connected);
                console.log("System: Connected!");
            });

            connection.on("Error", (message: string) => {
                console.error(message);
            });

            connection.on("Notify", (newNotifications: PlayerNotification[]) => {
                console.log("System: Notify");
                setNotificationQueue(prev => [...prev, ...newNotifications]);
            });

            connection.on("SetCountOfOnlinePlayers", (count: number) => {
                console.log("System: SetCountOfOnlinePlayers");
                setCurrentOnline(count);
            });
        }
    }, [connection]);

    return { connectionError: error, connectionState: state, currentOnline, notificationQueue, setNotificationQueue }
}