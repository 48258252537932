import { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../App';
import { FriendListItem } from '../models/FriendListItem';
import { AxiosErrorToString } from '../utils/AxiosUtils';

export function useFriends() {
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>();
    const [error, setError] = useState<string>();
    const [friends, setFriends] = useState<FriendListItem[]>();

    async function fetch() {
        if (!auth) return;

        setLoading(true);

        const api = await auth.getApi();

        api.get<FriendListItem[]>("friends/GetFriendList")
            .then((response) => {
            setLoading(false);
            setFriends(response.data);
        }).catch((error: AxiosError) => {
            setLoading(false);
            setError(AxiosErrorToString(error));
        });
    }

    const forseRefresh = () => {
        fetch();
    }

    useEffect(() => {
        fetch();
    }, []);

    return { loading, error, friends, forseRefresh };
}