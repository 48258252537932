import { AxiosError } from "axios";
import { ChatMessage } from "../models/Chat";
import { FriendListItem } from "../models/FriendListItem";
import { ProfileBadge } from "../models/Profile";
import { AxiosErrorToString } from "../utils/AxiosUtils";
import { Auth } from "../hooks/useAuth";

export async function getPlayerBadge(playerId: number, auth: Auth): Promise<ProfileBadge | undefined> {
    const api = await auth.getApi();
    try {
        const response = await api.get<ProfileBadge>("profile/GetProfileBadge", { params: { playerId } });
        return response.data;
    } catch (e) {
        console.error(AxiosErrorToString(e as AxiosError)); 
    }
    return undefined;
}

export async function getChatMessage(messageId: number, auth: Auth): Promise<ChatMessage | undefined> {
    const api = await auth.getApi();
    try {
        const response = await api.get<ChatMessage>("chat/GetChatMessage", { params: { messageId } });
        return response.data;
    } catch (e) {
        console.error(AxiosErrorToString(e as AxiosError));
    }
    return undefined;
}

export async function getFriendship(friendId: number, auth: Auth): Promise<FriendListItem | undefined> {
    const api = await auth.getApi();
    try {
        const response = await api.get<FriendListItem>("friends/GetFriendship", { params: { friendId } });
        return response.data;
    } catch (e) {
        console.error(AxiosErrorToString(e as AxiosError));
    }
    return undefined;
}