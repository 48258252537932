import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../App";
import { Chat } from "../models/Chat";
import { AxiosErrorToString } from "../utils/AxiosUtils";

export function useChatList() {
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>();
    const [error, setError] = useState<string>();
    const [chatList, setChatList] = useState<Chat[]>([]);

    async function fetch() {
        if (!auth) return;
       
        setLoading(true);

        const api = await auth.getApi();

        api.get<Chat[]>("chat/GetChats")
            .then((response) => {
            setLoading(false);
            setChatList(response.data);
        }).catch((error: AxiosError) => {
            setLoading(false);
            setError(AxiosErrorToString(error));
        });
    }

    const forseRefresh = () => {
        fetch();
    };

    function setChatReadState(chatId: number | undefined, isRead: boolean) {
        if (chatList === undefined) return;
        if (chatId === undefined) return;

        const updatedChatList = [...chatList];
        const chatIndex = updatedChatList.findIndex(chat => chat.id === chatId);

        if (chatIndex !== -1) {
            updatedChatList[chatIndex].isRead = isRead;
        }

        setChatList(updatedChatList);
    };

    useEffect(() => {
        fetch();
    }, [auth?.myProfile]);

    return { loading, error, chatList, forseRefresh, setChatReadState };
}