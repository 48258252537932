import React from 'react';

interface BoxProps {
    title?: string,
    colorClass?: string,
    children: React.ReactNode
}

export function Box(props: BoxProps) {
    return (
        <div className={"content-box " + props.colorClass} >
            {props.title && <h3 className="block-title">{props.title}</h3>}
            {props.children}
        </div>
  );
}

export function BoxMini(props: BoxProps) {
    return (
        <div className={"content-box-mini " + props.colorClass} >
            {props.title && <h5 className="block-title-mini">{props.title}</h5>}
            {props.children}
        </div>
    );
}