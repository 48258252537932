import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../App";
import { InventoryItem } from "../models/ContentSystem";
import { AxiosErrorToString } from "../utils/AxiosUtils";

export function useInventory() {
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>();
    const [error, setError] = useState<string>();
    const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>();

    async function fetch() {
        if (!auth) return;

        setLoading(true);

        const api = await auth.getApi();

        api.get<InventoryItem[]>("profile/GetInventory")
            .then((response) => {
            setLoading(false);
            setInventoryItems(response.data);
        }).catch((error: AxiosError) => {
            setLoading(false);
            setError(AxiosErrorToString(error));
        });
    }

    useEffect(() => {
        fetch();
    }, []);

    return { loading, error, inventoryItems };
}