import { HubConnectionBuilder } from '@microsoft/signalr';
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../App";
import { API_URL } from "../Global";
import { ChatMessage } from "../models/Chat";
import { isNullOrEmpty } from "../utils/commonUtils";

export enum ChatState {
    NotAuthorized,
    Loading,
    Connected,
    Error,
    Reconnecting
}

export function useChat() {
    const auth = useContext(AuthContext);
    const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [messagesGrouped, setMessagesGrouped] = useState<ChatMessage[][]>([]);
    const [state, setState] = useState<ChatState>(ChatState.Loading);
    const [error, setError] = useState<string>();
    const [selectedChatId, setSelectedChatId] = useState<number>();
    const [notifiedChatId, setNotifiedChatId] = useState<number>();

    // Group messages
    useEffect(() => {
        setMessagesGrouped(groupMessages(messages));
    }, [messages]);

    // Create connection
    useEffect(() => {
        if (!auth?.authenticated) {
            if (connection) {
                connection.stop();
                setState(ChatState.Loading);
            }

            if (auth?.authenticated !== undefined) setState(ChatState.NotAuthorized);

            return;
        }

        const newConnection = new HubConnectionBuilder()
            .withUrl(API_URL + "hubs/chat", {
                accessTokenFactory: async () => {
                    const token = await auth.getAccessToken();
                    return token ?? "";
                },
            })
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, [auth?.authenticated]);

    // Setup and Start connection
    useEffect(() => {
        if (!connection) {
            return;
        }

        
       

        connection.start().then(() => {
            setState(ChatState.Connected);
            console.log("Chat: Connected!");
        }).catch((e) => {
            setState(ChatState.Error);
            setError(e.message);
        });

        connection.onreconnecting((e) => {
            setState(ChatState.Reconnecting);
            setError(e?.cause + ". " + e?.message + " Reconnecting...");
        });

        connection.onreconnected(() => {
            setState(ChatState.Connected);
            console.log("Chat: Connected!");
        });

        connection.on("Error", (message: string) => {
            console.error(message);
        });

        connection.on("UpdateChat", (chatMessages: ChatMessage[]) => {
            setMessages(chatMessages);
        });

        connection.on("AddMessage", (chatMessage: ChatMessage) => {
            setMessages((prevMessages) => [...prevMessages, chatMessage]);
        });

        connection.on("RemoveMessage", (messageId: number) => {
            setMessages((prevMessages) =>
                prevMessages.filter((message) => message.id !== messageId)
            );
        });

        connection.on("NewMessageNotice", (chatId: number) => {
            setNotifiedChatId(chatId);
        });

    }, [connection]);

    // Set selected chat
    useEffect(() => {
        setChat(selectedChatId);
    }, [selectedChatId, state]);

    // Close connection on close chat
    useEffect(() => {
        return () => {
            if (connection) {
                console.log("Close chat connection");
                connection.stop();
            }
        };
    }, [connection]);

    const setChat = (chatId: number | undefined) => {
        if (connection && state === ChatState.Connected && chatId !== undefined) {
            connection.invoke("Subscribe", chatId);
            console.log("Chat: Subscribe to " + chatId);
        }
    }

    const sendMessage = (message: string) => {
        if (connection && !isNullOrEmpty(message)) {
            connection.invoke("PublishMessage", message);
        }
    };

    const removeMessage = (messageId: number) => {
        if (connection) {
            connection.invoke("RemoveMessage", messageId);
        }
    };

    return { messagesGrouped, state, error, selectedChatId, setSelectedChatId, sendMessage, removeMessage, notifiedChatId, setNotifiedChatId };
}

function groupMessages(msgs: ChatMessage[]): ChatMessage[][] {
    const groupedMessages: ChatMessage[][] = [];

    let currentGroup: ChatMessage[] = [];
    let previousMessage: ChatMessage | null = null;

    for (const message of msgs) {
        if (
            previousMessage &&
            (message.profile.id !== previousMessage.profile.id ||
                (currentGroup.length > 0 && new Date(message.dateUtc).getTime() - new Date(currentGroup[0].dateUtc).getTime() > 60000))
        ) {
            groupedMessages.push(currentGroup);
            currentGroup = [];
        }

        currentGroup.push(message);
        previousMessage = message;
    }

    if (currentGroup.length > 0) {
        groupedMessages.push(currentGroup);
    }

    return groupedMessages;
}