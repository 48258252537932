import React, { MouseEventHandler } from 'react';
import { InventoryItem, ItemData, ItemRarity } from '../models/ContentSystem';
import { getItemImageUrl } from '../utils/commonUtils';


interface Props {
    inventoryItem: InventoryItem
}

function getColorByRarity(rarity: ItemRarity) {
    switch (rarity) {
        case ItemRarity.Rare:
            return "#4E99E5";
        case ItemRarity.Epic:
            return "#D840FF";
        case ItemRarity.Legend:
            return "#FFBF1C";
        case ItemRarity.Cosmic:
            return "#FF005E";
        default:
            return "#585858";
    }
}

export function InventoryItemComponent(props: Props) {
    return (
        <div className="inventory-item" style={{ borderColor: getColorByRarity(props.inventoryItem.itemData.rarity) }}>
          <img className="inventory-item-img" src={getItemImageUrl(props.inventoryItem.itemData.id)} />
          <div className="inventory-item-count">x{props.inventoryItem.count}</div>
      </div>
  );
}

interface SelectorProps {
    item: ItemData,
    selected: boolean,
    onClick: MouseEventHandler<HTMLButtonElement>
}

export function InventoryItemSelector(props: SelectorProps) {
    if (props.selected) {
        return (
            <div className="inventory-item" style={{ borderColor: getColorByRarity(props.item.rarity) }}>
                <img className="inventory-item-img" src={getItemImageUrl(props.item.id)} />
                <div className="inventory-item-selected">SELECTED</div>
            </div>
        );
    } else {
        return (
            <button className="inventory-item scale-on-hover" style={{ borderColor: getColorByRarity(props.item.rarity) }} onClick={props.onClick}>
                <img className="inventory-item-img" src={getItemImageUrl(props.item.id)} />
            </button>
        );
    }
}