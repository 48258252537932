import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Button, CloseButton, Modal } from 'react-bootstrap';
import { AuthContext, UniversalModalContext } from '../App';
import useFormField from '../hooks/useFormField';
import { AxiosErrorToString } from '../utils/AxiosUtils';
import { ErrorIndicator } from './ErrorIndicator';
import { GoogleLoginButton } from './GoogleLoginButton';
import { LoadingIndicator } from './LoadingIndicator';

enum State {
    None,
    ConfirmEmail,
    Error,
    Loading,
    LoggedIn
}

export function LoginModal() {
    const auth = useContext(AuthContext);
    const modal = useContext(UniversalModalContext);

    const [state, setState] = useState<State>(State.None);
    const [singInMode, setSingInMode] = useState<boolean>(true);
    const [errorText, setErrorText] = useState<string>();

    const emailField = useFormField();
    const passwordField = useFormField();
    const codeField = useFormField();

    useEffect(() => {
        if (auth?.authenticated) {
            setState(State.LoggedIn);
            emailField.setValue("");
            passwordField.setValue("");
        } else {
            setState(State.None);
        }
    }, [auth?.authenticated])

    const handleRegister = async () => {
        try {
            setState(State.Loading);
            await auth?.register(emailField.value, passwordField.value);
            setState(State.ConfirmEmail);
        } catch (e) {
            const error = e as AxiosError;
            setState(State.Error);
            setErrorText(AxiosErrorToString(error));
        }
    };

    const handleLogin = async () => {
        try {
            setState(State.Loading);
            await auth?.login(emailField.value, passwordField.value);
            setState(State.None);
            modal?.hide();
        } catch (e) {
            const error = e as AxiosError;
            setState(State.Error);
            setErrorText(AxiosErrorToString(error));
        }
    };

    const handleConfirmEmail = async () => {
        if (codeField.value.length !== 6) return;

        try {
            setState(State.Loading);
            await auth?.confirmEmail(emailField.value, codeField.value);
            codeField.setValue("");
            setState(State.None);
            modal?.hide();
        } catch (e) {
            const error = e as AxiosError;
            setState(State.Error);
            setErrorText(AxiosErrorToString(error));
        }
    }

    return (
        <div className="text-center">
            <img className="mb-4" src="/images/tdid_logo.png" alt="TDID" width="160" />  <br />

            {state === State.Loading && <LoadingIndicator />}
            {state === State.Error &&
                <>
                    <ErrorIndicator errorText={errorText} />
                    <button type="submit" className="btn scale-on-hover" onClick={() => setState(State.None)}>Back</button>
                </>
            }
            {state === State.ConfirmEmail &&
                <>
                    <p>A code was sent to your email, please enter it below</p>
                    <div className="input-group mb-4">
                        <input className="form-control" type="email" name="code" placeholder="Code" {...codeField.params} />
                        <button className="btn" onClick={handleRegister}>Request new</button>
                    </div>
                    <div className="profile-tag" style={{ backgroundColor: "#533E17", color: "#FFBC41" }}>
                        Please check spam if you do not receive the code
                    </div>
                    <button className="btn scale-on-hover mt-4 me-2" onClick={() => setState(State.None)}>Back</button>
                    <button className="btn btn-primary scale-on-hover mt-4" onClick={handleConfirmEmail}>Confirm</button>
                </>
            }
            {state === State.LoggedIn && <p className="text-success">You have successfully logged in</p>}
            {state === State.None &&
                <>
                    <GoogleLoginButton />

                    <div className="divider d-flex align-items-center my-4">
                        <p className="text-center fw-bold mx-3 mb-0">Or</p>
                    </div>

                    {singInMode &&
                        <>
                            <input className="form-control" type="email" name="email" id="email" placeholder="Email" {...emailField.params} />
                            <input className="form-control mt-2" type="password" name="password" id="password" placeholder="Password" {...passwordField.params} />
                            <div className="forgot-password-text mt-1"><a className="link-secondary" href="#!">Forgot password?</a></div>
                            <button className="btn btn-primary scale-on-hover mt-5" onClick={handleLogin}>Login</button>
                            <div className="mt-4">Don't have an account? <a onClick={() => setSingInMode(false)} href="#!" className="link-danger">Register</a></div>
                        </>
                        ||
                        <>
                            <input className="form-control" type="email" name="email" id="email" placeholder="Email" {...emailField.params} />
                            <input className="form-control mt-2" type="password" name="password" id="password" placeholder="Password" {...passwordField.params} />
                            <button className="btn btn-primary scale-on-hover mt-5" onClick={handleRegister}>Register</button>
                            <div className="mt-4">Already have an account? <a onClick={() => setSingInMode(true)} href="#!" className="link-success">Login</a></div>
                        </>
                    }
                </>
            }
        </div>
  );
}