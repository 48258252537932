import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from 'react-router-dom'
import './css/core.scss';
import { Auth, useAuth } from './hooks/useAuth';
import { Notifications, NotificationsRender, useNotificationsContext } from './solutions/notifications';
import { SystemHubContext, useSystemHubContext } from './solutions/systemConnection';
import { PlayerNotificationsHandleComponent } from './solutions/playerNotificationsHandler';

// Components
import { Page } from './components/Page';
import { UniversalModal, UniversalModalComponent, useUniversalModal } from './components/UniversalModal';

// Pages
import { HomePage } from './pages/HomePage'
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { ProfilePage } from './pages/ProfilePage';
import { MyProfilePage } from './pages/MyProfilePage';
import { ChatPage } from './pages/ChatPage';
import { GoogleCallbackPage } from './pages/GoogleCallbackPage';
import { AdminPage } from './components/AdminPage';
import { GameItemsPage } from './pages/GameItemsPage';
import { InventoryManagement } from './pages/InventoryManagement';
import { ViewLogsPage } from './pages/ViewLogsPage';


export const AuthContext = React.createContext<Auth | null>(null);
export const NotificationsContext = React.createContext<Notifications | null>(null);
export const SystemContext = React.createContext<SystemHubContext | null>(null);
export const UniversalModalContext = React.createContext<UniversalModal | null>(null);

export function App() {
    const authContext = useAuth();
    const systemHubContext = useSystemHubContext(authContext);
    const notificationsContext = useNotificationsContext();
    const universalModalContext = useUniversalModal();

    return (
        <>
            <NotificationsContext.Provider value={notificationsContext}>
                <NotificationsRender />

                <UniversalModalContext.Provider value={universalModalContext}>
                    <AuthContext.Provider value={authContext}>
                        <SystemContext.Provider value={systemHubContext}>
                            <PlayerNotificationsHandleComponent />
                            <UniversalModalComponent {...universalModalContext.componentProps} />

                            <Routes>
                                <Route path="/" element={<Page />}>
                                    <Route index element=<HomePage /> />
                                    <Route path="privacy-policy" element=<PrivacyPolicyPage /> />
                                    <Route path="player/:id" element=<ProfilePage /> />
                                    <Route path="my-profile" element=<MyProfilePage /> />
                                    <Route path="chat" element=<ChatPage /> />
                                    <Route path="google-callback" element=<GoogleCallbackPage backToGame={false} /> />
                                    <Route path="google-callback-backtogame" element=<GoogleCallbackPage backToGame={true} /> />
                                    <Route path="adm/" element={<AdminPage />}>
                                        <Route path="allItems" element=<GameItemsPage /> />
                                        <Route path="viewLogs" element=<ViewLogsPage /> />
                                        <Route path="inventory-management/:id" element=<InventoryManagement/> />
                                        <Route path="*" element=<NotFoundPage /> />
                                    </Route>
                                    <Route path="*" element=<NotFoundPage /> />
                                </Route>
                            </Routes>
                        </SystemContext.Provider>
                    </AuthContext.Provider>
                </UniversalModalContext.Provider>
            </NotificationsContext.Provider>
        </>
  );
}
