import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { acceptFriend, removeFriend, sendFriendRequest } from '../api/friends';
import { AuthContext, UniversalModalContext } from '../App';
import { BigProfileViewer } from '../components/BigProfileViewer';
import { Box, BoxMini } from '../components/Box';
import { showConfirmationModal } from '../components/ConfirmationModal';
import { ErrorIndicator } from '../components/ErrorIndicator';
import { InventoryItemComponent } from '../components/InventoryItemComponent';
import { LoadingIndicator } from '../components/LoadingIndicator';
import { ProfileTagsViewer } from '../components/ProfileTagsViewer';
import { API_URL } from '../Global';
import { useFriends } from '../hooks/useFriends';
import { useOtherInventory } from '../hooks/useOtherInventory';
import { usePublicProfile } from '../hooks/usePublicProfile';
import { FriendListItemSection } from '../models/FriendListItem';
import { AccessLevel } from '../models/Profile';
import { isNullOrEmpty } from '../utils/commonUtils';

interface Props {
    id: number,
}

export function OtherProfilePage({ id }: Props) {

    const auth = useContext(AuthContext);

    const profile = usePublicProfile(id);

    // Friends
    const friends = useFriends();
    let isFriend: boolean = false;
    let isFriendRequestSent: boolean = false;
    let isFriendRequestReceived: boolean = false;
    if (friends.friends !== undefined && friends.friends.length > 0) {
        for (var i = 0; i < friends.friends.length; i++) {
            if (friends.friends[i].profile.id === profile.profile?.id) {
                switch (friends.friends[i].section) {
                    case FriendListItemSection.Friends:
                        isFriend = true;
                        break;
                    case FriendListItemSection.ReceivedRequests:
                        isFriendRequestReceived = true;
                        break;
                    case FriendListItemSection.SentRequests:
                        isFriendRequestSent = true;
                        break;
                    default:
                }
            }
        }
    }

    // Other
    const modal = useContext(UniversalModalContext);

    // For admin`s
    const otherInventory = useOtherInventory(id);


    // Friend management handlers
    const handleSendFriendRequest = async (friendId: number | undefined) => {
        await sendFriendRequest(friendId, auth);
        friends.forseRefresh();
    }
    const handleAcceptFriend = async (friendId: number | undefined) => {
        await acceptFriend(friendId, auth);
        friends.forseRefresh();
    }
    const handleRemoveFriend = async (friendId: number | undefined) => {
        await removeFriend(friendId, auth);
        friends.forseRefresh();
    }

    if (!auth?.authenticated) {
        return (
            <>
                <Box title="Log in" colorClass="bg-red">
                    Log in or register to view profile
                </Box>
            </>
        );
    }

    if (profile.loading) {
        return (
            <Box title="Profile"> <LoadingIndicator /> </Box>
        );
    }

    return (
        <>
            <Box title="Profile" colorClass="">
                <BigProfileViewer profileBadge={profile.profile}>
                    <ProfileTagsViewer profile={profile.profile} isFriend={isFriend} />
                </BigProfileViewer>

                <div className="row gx-2 justify-content-center">
                    <div className="col">
                        <BoxMini colorClass="bg-yellow nowrap mt-2">
                            <div className="d-inline level-border-container" style={{ width: "50px", height: "50px" }}>
                                <img src={API_URL + "img/level-borders/" + (profile.profile?.level === undefined ? "1" : Math.ceil(profile.profile?.level / 20)) + ".png"} alt="Level Border" width="50px" />
                                <div className="level-border-text fs-6 fw-bold">1</div>
                            </div>
                            <span className="fs-6 fw-bold ps-2">Level</span>
                        </BoxMini>
                    </div>
                    <div className="col">
                        <BoxMini colorClass="bg-blue nowrap mt-2">
                            <img src={API_URL + "img/tiers/" + (profile.profile?.techTreeTier === undefined ? "1" : profile.profile?.techTreeTier + 1) + ".png"} alt="Tech Tier" width="50px" />
                            <span className="fs-6 fw-bold ps-2">Technology Tier {profile.profile?.techTreeTier === undefined ? "1" : profile.profile?.techTreeTier + 1}</span>
                        </BoxMini>
                    </div>
                </div>

                <div className="row gx-2 mt-2">
                    {!isFriend && !isFriendRequestSent && !isFriendRequestReceived && 
                        <div className="col">
                            <button className="btn nowrap mt-2" style={{ width: "100%" }} onClick={() => { handleSendFriendRequest(profile.profile?.id); }}>Send friend request</button>
                        </div>    
                    }
                </div>

                {isFriendRequestReceived &&
                    <BoxMini colorClass="bg-blue">
                        <div className="row">
                            <div className="col-md-auto my-auto text-center">
                                <span className="fw-bold">Friendship request received</span>
                            </div>
                            <div className="col d-flex justify-content-md-end justify-content-center mt-md-0 mt-2">
                                <button type="button" className="btn btn-primary" onClick={() => { handleAcceptFriend(profile.profile?.id); }}>Accept</button>
                                <Button variant="danger" className="ms-2" onClick={() =>
                                    showConfirmationModal(modal, "Reject friendship", "Reject", <>{"Are you sure you want to reject the friendship request from " + profile.profile?.nickname + "?"}</>, () => handleRemoveFriend(profile.profile?.id))
                                }>Reject</Button>
                            </div>
                        </div>
                    </BoxMini>
                }

                {isFriendRequestSent &&
                    <BoxMini colorClass="bg-green">
                        <div className="row">
                            <div className="col-md-auto my-auto text-center">
                                <span className="fw-bold">Friendship request sent</span>
                            </div>
                            <div className="col d-flex justify-content-md-end justify-content-center mt-md-0 mt-2">
                                <button type="button" className="btn btn-danger scale-on-hover ms-2" onClick={() => { handleRemoveFriend(profile.profile?.id); }}>Reject</button>
                            </div>
                        </div>
                    </BoxMini>
                }
            </Box>
            {auth.myProfile?.accessLevel != undefined && auth.myProfile?.accessLevel >= AccessLevel.Moder &&
                <Box title={profile.profile?.nickname + " INVENTORY"}>
                    <div className="inventory-grid">
                        {otherInventory.loading && <LoadingIndicator />}
                        {!otherInventory.loading && !isNullOrEmpty(otherInventory.error) && <ErrorIndicator errorText={otherInventory.error} />}
                        {otherInventory.inventoryItems?.map((value) => <InventoryItemComponent key={value.itemData.id} inventoryItem={value} />)}
                    </div>
                    <div className="text-center pt-3">
                        <Link className="btn" to={"/adm/inventory-management/" + profile.profile?.id} style={{ width: "100%" }} >{"Change " + profile.profile?.nickname + "`s inventory"}</Link>
                    </div>
                </Box>
            }
        </>
    );
}