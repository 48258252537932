import React, { useContext } from 'react';
import { AuthContext, SystemContext } from '../App';
import { PublicProfile } from '../models/Profile';
import { SystemConnectionState } from '../solutions/systemConnection';


interface Props {
    profile: PublicProfile | undefined;
    isFriend?: boolean;
}


export function ProfileTagsViewer({ profile, isFriend }: Props) {
    const auth = useContext(AuthContext);
    const system = useContext(SystemContext);

    const isMe = auth?.myProfile?.id === profile?.id;

    if (!profile) {
        return (<></>);
    }

    return (
        <div className="row row-cols-auto g-0">
            {(profile.online || (isMe && system?.connectionState === SystemConnectionState.Connected)) &&
                <div className="col me-2 mt-2">
                    <span className="profile-tag" style={{ backgroundColor: "#008A22" }}>Online</span>
                </div>
            }
            {profile.accessLevel == 1 &&
                <div className="col me-2 mt-2">
                    <span className="profile-tag" style={{ backgroundColor: "#BC6400" }}>Moder</span>
                </div>
            }
            {profile.accessLevel > 1 &&
                <div className="col me-2 mt-2">
                    <span className="profile-tag" style={{ backgroundColor: "#B10000" }}>Admin</span>
                </div>
            }
            {isFriend &&
                <div className="col me-2 mt-2">
                    <span className="profile-tag" style={{ backgroundColor: "#BC9700" }}>Friend</span>
                </div>
            }
            {(new Date(profile.premiumExpirationDate ?? "").getTime() > new Date().getTime()) &&
                <div className="col me-2 mt-2">
                    <span className="profile-tag" style={{ backgroundColor: "#A60095" }}>Premium</span>
                </div>
            }
        </div>
    );
}