import { Auth } from "../hooks/useAuth";

export const changeNickname = async (nickname: string | undefined, auth: Auth | null) => {
    if (!nickname || !auth) return;
    const api = await auth.getApi();
    await api.post("profile/ChangeNickname", null, { params: { nickname } });
}

export const changeAvatar = async (avatarId: string | undefined, auth: Auth | null) => {
    if (!avatarId || !auth) return;
    const api = await auth.getApi();
    await api.post("profile/ChangeAvatar", null, { params: { avatarId } });
}