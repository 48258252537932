import React from 'react';
import { Chat, ChatType } from '../../models/Chat';
import { GetChatMetadata } from '../../utils/chatUtils';
import { CircularAvatar } from '../CircularAvatar';

interface Props {
    chat: Chat;
    selected: boolean;
    onClick: (chat: Chat) => void;
}

export function ChatListItemComponent({ chat, selected, onClick }: Props) {
    const chatMetadata = GetChatMetadata(chat);

    return (
        <div className={"row g-0 p-2 mb-1 chats-list-item" + (selected ? "-selected" : " scale-on-hover")} onClick={() => { onClick(chat); }}>
            <div className="col-auto me-1">
                {chat.associatedProfile &&
                    <CircularAvatar profile={chat.associatedProfile} size={36} />
                    ||
                    <img src={chatMetadata.chatIconUrl} className="rounded-circle" height="36" alt="Chat Icon" />
                }
            </div>
            <div className="col text-truncate">
                {!chat.isRead && <img src="/images/redcircle.png" className="me-1" height="10" alt="Notification" />}
                {chatMetadata.chatName}
            </div>
        </div>
    );
}