import { useContext, useEffect, useState } from "react";
import { NotificationsContext } from "../App";

export interface Notifications {
    notifications: Notification[];
    addNotification: (n: Notification) => void;
    removeNotification: (n: Notification) => void;
    removeFirstNotifications: (count: number) => void;
}

export interface Notification {
    type: 'info' | 'warn' | 'error';
    content: React.ReactNode;
    lifetimeInSeconds?: number;
}

export function useNotificationsContext() {
    const [notifications, setNotifications] = useState<Notification[]>([]);

    const addNotification = (n: Notification) => {
        setNotifications(prev => [...prev, n]);
    }
    const removeNotification = (notification: Notification) => {
        setNotifications(prev => prev.filter(n => n !== notification));
    };
    const removeFirstNotifications = (count: number) => {
        setNotifications(prev => prev.slice(count));
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setNotifications(prev => {
                let updatedNotifications = prev.map(n => {
                    if (n.lifetimeInSeconds !== undefined) return { ...n, lifetimeInSeconds: n.lifetimeInSeconds - 1 };
                    return n;
                });

                return updatedNotifications.filter(n => !(n.lifetimeInSeconds !== undefined && n.lifetimeInSeconds <= 0));
            });
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const notificationsContext: Notifications = { notifications, addNotification, removeNotification, removeFirstNotifications };

    return notificationsContext;
}

export interface NotificationsRenderProps {

}

export function NotificationsRender({ }: NotificationsRenderProps) {
    const notificationsContext = useContext(NotificationsContext);

    if (notificationsContext?.notifications.length === 0) return (<></>);

    return (
        <div className="notifications-container">
            {notificationsContext?.notifications.map((n, i) => {
                let notificationClass = "notification";

                if (n.type == 'error') notificationClass += " notification-error";
                if (n.type == 'warn') notificationClass += " notification-warning";

                if (i > 2) {
                    return (<></>);
                }

                return (
                    <div key={i} className={notificationClass} >
                        <div className="row">
                            <div className="col">
                                {n.content}
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={() => notificationsContext.removeNotification(n)}></button>
                            </div>
                        </div>
                    </div>
                )
            })}

            {notificationsContext && notificationsContext.notifications.length >= 2 && <div className="notifications-clear-container"> <div className="notifications-clear link-secondary" onClick={() => {
                notificationsContext.removeFirstNotifications(3);
            }}>Clear All</div></div>}
        </div>
    );
}