import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../App";
import { PublicProfile } from "../models/Profile";
import { AxiosErrorToString } from "../utils/AxiosUtils";

export function usePublicProfile(id: number) {
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>();
    const [error, setError] = useState<string>();
    const [profile, setProfile] = useState<PublicProfile>();

    async function fetch() {
        if (!auth) return;

        setLoading(true);

        const api = await auth.getApi();

        api.get<PublicProfile>("profile/GetPublicProfile", { params: { id } })
            .then((response) => {
            setLoading(false);
            setProfile(response.data);
        }).catch((error: AxiosError) => {
            setLoading(false);
            setError(AxiosErrorToString(error));
        });
    }

    useEffect(() => {
        fetch();
    }, []);

    return { loading, error, profile };
}