export enum NotificationType {
    Text = 0,
    FriendChatMessage = 1,
    FriendRequest = 2,
    // Infraction = 3,
    // AddXp = 4,
    // AddCry = 5,
    // AddItem = 6,
    // TeamInvitation = 7,
}

export interface PlayerNotification {
    type: NotificationType;
    data: string;
}

// Text
export enum TextNotificationType {
    Info,
    Warn,
    Error,
}

export interface TextNotification {
    Text: string;
    Type: TextNotificationType;
    ModalMode: boolean;
    LifetimeInSeconds?: number;
}

// FriendChatMessage
export interface FriendChatMessageNotification {
    ChatMessageId: number;
}

// FriendRequest
export interface FriendRequestNotification {
    FriendId: number;
}