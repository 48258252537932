import { useState } from "react";
import { useItems } from "../hooks/useItems";
import { ItemData, ItemRarity, ItemType } from "../models/ContentSystem";
import { isNullOrEmpty } from "../utils/commonUtils";
import { ErrorIndicator } from "./ErrorIndicator";
import { GameItemComponent } from "./GameItemComponent";
import { LoadingIndicator } from "./LoadingIndicator";

interface Props{
    listItemEntry: (item: ItemData) => React.ReactNode;
}

export function GameItemsViewer(props: Props) {
    const inventory = useItems();

    const [itemIdSelector, setitemIdSelector] = useState('');
    const [raritySelector, setRaritySelector] = useState<ItemRarity>();
    const [typeSelector, setTypeSelector] = useState<ItemType>();

    const filteredItems = inventory.items?.filter(item => {
        const isMatchingName = item.id.toLowerCase().includes(itemIdSelector.toLowerCase());
        const isMatchingRarity = raritySelector === undefined || item.rarity === raritySelector;
        const isMatchingType = typeSelector === undefined || item.type === typeSelector;

        return isMatchingName && isMatchingRarity && isMatchingType;

    });

    return (
        <>
            <div className="d-flex pb-3">
                <input
                    className="form-contol w-50 me-3"
                    type="text"
                    name="itemIdSelector"
                    id="itemIdSelector"
                    placeholder="Item ID"
                    onChange={(e) => setitemIdSelector(e.target.value)}
                >
                </input>
                <select
                    className="form-select w-25 form-control me-3"
                    name="raritySelector"
                    id="raritySelector"
                    onChange={(e) => setRaritySelector(e.target.value === "-1" ? undefined : parseInt(e.target.value))}>
                    <option value={-1}>All rarities</option>
                    <option value={ItemRarity.Common}>Common</option>
                    <option value={ItemRarity.Rare}>Rare</option>
                    <option value={ItemRarity.Epic}>Epic</option>
                    <option value={ItemRarity.Legend}>Legend</option>
                    <option value={ItemRarity.Cosmic}>Cosmic</option>
                </select>
                <select
                    className="form-select w-25 form-control"
                    name="typeSelector"
                    id="typeSelector"
                    onChange={(e) => setTypeSelector(e.target.value === "-1" ? undefined : parseInt(e.target.value))}>
                    <option value={-1}>All types</option>
                    <option value={ItemType.Case}>Case</option>
                    <option value={ItemType.Avatar}>Avatar</option>
                    <option value={ItemType.Frame}>Frame</option>
                    <option value={ItemType.BaseSkin}>BaseSkin</option>
                    <option value={ItemType.UnitSkin}>UnitSkin</option>
                </select>
            </div>

            <div>
                {inventory.loading && <LoadingIndicator />}
                {!inventory.loading && !isNullOrEmpty(inventory.error) && <ErrorIndicator errorText={inventory.error} />}
                {filteredItems?.map((value) => props.listItemEntry(value))}
            </div>
        </>
    )

}