import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom';
import { AuthContext } from '../App';
import { AccessLevel } from '../models/Profile';
import { Box } from './Box'

export function AdminPage() {
    const auth = useContext(AuthContext);
    const accessLevelStr = AccessLevel[auth?.myProfile?.accessLevel ?? 0]

    return (
        <>
            <Box colorClass="bg-red">
                <h3 className="block-title"><img className="" src="/images/icons/adm.png" alt="Discord" width="32px" /> ADMIN PANEL</h3>
                <span>{"Hi " + auth?.myProfile?.nickname + "! Your Access Level is " + accessLevelStr}</span>
            </Box>
            <Outlet />
        </>
  )
}

