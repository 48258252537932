import { useState } from "react";

function useFormField(initialValue: string = "") {
    const [value, setValue] = useState(initialValue);
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);
    return {
        value,
        setValue,
        params: { value, onChange },
    };
};

export default useFormField;