import { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { acceptFriend, removeFriend, sendFriendRequest } from '../api/friends';
import { changeAvatar, changeNickname } from '../api/profile';
import { AuthContext, UniversalModalContext } from '../App';
import { BigProfileViewer } from '../components/BigProfileViewer';
import { Box, BoxMini } from '../components/Box';
import { showConfirmationModal } from '../components/ConfirmationModal';
import { CustomModal } from '../components/CustomModal';
import { ErrorIndicator } from '../components/ErrorIndicator';
import { FriendLink } from '../components/FriendLink';
import { InventoryItemComponent, InventoryItemSelector } from '../components/InventoryItemComponent';
import { LoadingIndicator } from '../components/LoadingIndicator';
import { ProfileTagsViewer } from '../components/ProfileTagsViewer';
import { API_URL } from '../Global';
import useFormField from '../hooks/useFormField';
import { useFriends } from '../hooks/useFriends';
import { useInventory } from '../hooks/useInventory';
import { useLoadError } from '../hooks/useLoadError';
import { ItemType } from '../models/ContentSystem';
import { FriendListItemSection } from '../models/FriendListItem';
import { AccessLevel } from '../models/Profile';
import { AxiosErrorToString } from '../utils/AxiosUtils';
import { isNullOrEmpty } from '../utils/commonUtils';

export function MyProfilePage() {
    // My Profile
    const auth = useContext(AuthContext);
    const nicknameField = useFormField();
    const nicknameChange = useLoadError();
    const avatarChange = useLoadError();
    let currentAvatar = auth?.myProfile?.avatarId;
    if (isNullOrEmpty(currentAvatar)) currentAvatar = "defaultavatar";

    // Friends
    const friends = useFriends();
    const friendAdding = useLoadError();
    const [friendProfileId, setFriendProfileId] = useState<number>();
    let countOfFriendRequests = 0;
    let countOfFriendSents = 0;
    if (friends.friends !== undefined && friends.friends.length > 0) {
        for (var i = 0; i < friends.friends.length; i++) {
            switch (friends.friends[i].section) {
                case FriendListItemSection.ReceivedRequests:
                    countOfFriendRequests++;
                    break;
                case FriendListItemSection.SentRequests:
                    countOfFriendSents++;
                    break;
            }
        }
    }

    // Inventory
    const inventory = useInventory();

    // Other
    const modal = useContext(UniversalModalContext);
    const [showChangeNickname, setShowChangeNickname] = useState(false);
    const [showSelectAvatar, setShowSelectAvatar] = useState(false);
    const [showAddFriend, setShowAddFriend] = useState(false);


    // Profile edit handlers
    const handleChangeNickname = async () => {
        nicknameChange.setLoading(true);
        
        try {
            await changeNickname(nicknameField.value, auth);
            setShowChangeNickname(false);
        } catch (e) {
            nicknameChange.setError(AxiosErrorToString(e as AxiosError));
        }

        auth?.refreshMyProfile();

        nicknameChange.setLoading(false);
    }
    const handleChangeAvatar = async (avatarId: string) => {
        avatarChange.setLoading(true);

        try {
            await changeAvatar(avatarId, auth);
            setShowSelectAvatar(false);
        } catch (e) {
            avatarChange.setError(AxiosErrorToString(e as AxiosError));
        }

        auth?.refreshMyProfile();

        avatarChange.setLoading(false);
    }

    // Friend management handlers
    const handleSendFriendRequest = async (friendId: number | undefined) => {
        friendAdding.setLoading(true);

        try {
            await sendFriendRequest(friendId, auth);
            setShowAddFriend(false);
        } catch (e) {
            friendAdding.setError(AxiosErrorToString(e as AxiosError));
        }

        friends.forseRefresh();

        friendAdding.setLoading(false);
    }
    const handleAcceptFriend = async (friendId: number | undefined) => {
        await acceptFriend(friendId, auth);
        friends.forseRefresh();
    }
    const handleRemoveFriend = async (friendId: number | undefined) => {
        await removeFriend(friendId, auth);
        friends.forseRefresh();
    }


    useEffect(() => {
        nicknameField.setValue(auth?.myProfile?.nickname ?? "")
    }, [auth?.myProfile]);


    if (!auth?.myProfile) {
        return (
            <Box title="My profile"> <LoadingIndicator /> </Box>
        );
    }

    return (
        <>
            <CustomModal title="Change nickname" show={showChangeNickname} setShow={setShowChangeNickname}  loadError={nicknameChange}>
                <div className="input-group">
                    <span className="input-group-text">Nickname</span>
                    <input className="form-control" type="text" name="nick" placeholder="Nickname" {...nicknameField.params} />
                </div>
                <div className="text-center mt-4">
                    <button className="btn btn-primary scale-on-hover" onClick={handleChangeNickname} >Change</button>
                </div>
            </CustomModal>

            <CustomModal title="Select avatar" show={showSelectAvatar} setShow={setShowSelectAvatar} loadError={avatarChange}>
                <div className="inventory-grid-mini">
                    {inventory.inventoryItems?.map((value) => { if (value.itemData.type === ItemType.Avatar) return <InventoryItemSelector key={value.itemData.id} item={value.itemData} selected={value.itemData.id === currentAvatar} onClick={(event) => { handleChangeAvatar(value.itemData.id) } } /> })}
                </div>
            </CustomModal>

            <CustomModal title="Add friend" show={showAddFriend} setShow={setShowAddFriend} loadError={friendAdding}>
                <input type="number" className="form-control" name="friendId" id="friendId" placeholder="Friend profile ID" value={friendProfileId} onChange={(event) => { setFriendProfileId(Number(event.currentTarget.value)) }} />
                <div className="text-center mt-4">
                    <button className="btn btn-primary scale-on-hover" onClick={() => { handleSendFriendRequest(friendProfileId); } } >Send request</button>
                </div>
            </CustomModal>

            <Box title="My profile">
                <BigProfileViewer profileBadge={auth?.myProfile}>
                    <ProfileTagsViewer profile={auth?.myProfile} />
                </BigProfileViewer>

                <BoxMini colorClass="bg-yellow  mt-2">
                    <div className="row">
                        <div className="col-auto">
                            <div className="level-border-container" style={{ width: "80px", height: "80px" }}>
                                <img src={API_URL + "img/level-borders/" + (auth?.myProfile?.level === undefined ? "1" : Math.ceil(auth.myProfile.level / 20)) + ".png"} alt="Level Border" width="80px" / >
                                <div className="level-border-text fs-4 fw-bold">1</div>
                            </div>
                        </div>
                        <div className="col">
                            <span className="fw-bold"> Level </span>
                            <div className="progress mt-1" style={{ backgroundColor: "gray" }}>
                                <div className="progress-bar" style={{ width: "0%", backgroundColor: "#ffd148" }}></div>
                            </div>
                            XP: {auth?.myProfile?.xp}/100
                        </div>
                    </div>
                </BoxMini>

                <div className="row gx-2 justify-content-center">
                    <div className="col">
                        <BoxMini colorClass="bg-blue nowrap mt-2">
                            <img src={API_URL + "img/tiers/" + (auth?.myProfile?.techTreeTier === undefined ? "1" : auth.myProfile.techTreeTier + 1) + ".png"} alt="Level Border" width="50px" />
                            <span className="fs-6 fw-bold ps-2">Technology Tier {auth?.myProfile?.techTreeTier === undefined ? "1" : auth.myProfile.techTreeTier + 1}</span>
                        </BoxMini>
                    </div>
                    <div className="col">
                        <BoxMini colorClass="bg-pink nowrap mt-2">
                            <img src={API_URL + "img/cry.png"} alt="Level Border" width="50px" />
                            <span className="fs-5 fw-bold ps-2">{auth?.myProfile?.cryCount}</span>
                        </BoxMini>
                    </div>
                </div>
                

                <div className="row gx-2 mt-3">
                    <div className="col">
                        <button className="btn nowrap mt-2" style={{ width: "100%" }} onClick={() => setShowChangeNickname(true)}>Change nickname</button>
                    </div>
                    <div className="col">
                        <button className="btn nowrap mt-2" style={{ width: "100%" }} onClick={() => setShowSelectAvatar(true)}>Change avatar</button>
                    </div>
                </div>
            </Box>

            <Box title="My friends">
                {friends.loading && <LoadingIndicator />}
                {!friends.loading && !isNullOrEmpty(friends.error) && <ErrorIndicator errorText={friends.error} />}

                {friends.friends?.length == 0 && <span className="text-secondary">List is empty...<br/></span>}

                {countOfFriendRequests > 0 && 
                    <BoxMini title="Received requests" colorClass="bg-blue mb-2">
                        {friends.friends?.map((friend) => {
                            if (friend.section === FriendListItemSection.ReceivedRequests) {
                                return (
                                    <FriendLink className="mb-2" key={friend.profile.id} profile={friend.profile} >
                                        <a className="icon-btn" onClick={() => { handleAcceptFriend(friend.profile.id); }}>
                                            <img src="/images/accept.png" alt="Accept" width="32px" />
                                        </a>
                                        <a className="icon-btn ms-2" onClick={() => {
                                            showConfirmationModal(modal, "Reject friendship", "Reject",
                                                <>
                                                    {"Are you sure you want to reject the friendship request from " + friend.profile.nickname + "?"}
                                                </>, () => handleRemoveFriend(friend.profile.id))
                                        }}>
                                            <img src="/images/remove.png" alt="Remove" width="32px" />
                                        </a>
                                    </FriendLink>
                                );
                            }
                        })}
                    </BoxMini>    
                }

                {countOfFriendSents > 0 &&
                    <BoxMini title="Sent requests" colorClass="bg-green mb-2">
                        {friends.friends?.map((friend) => {
                            if (friend.section === FriendListItemSection.SentRequests) {
                                return (
                                    <FriendLink className="mb-2" key={friend.profile.id} profile={friend.profile} >
                                        <a className="icon-btn" onClick={() => {
                                            handleRemoveFriend(friend.profile.id);
                                        }}>
                                            <img src="/images/remove.png" alt="Remove" width="32px" />
                                        </a>
                                    </FriendLink>
                                );
                            }
                        })}
                    </BoxMini>
                }

                {friends.friends?.map((friend) => {
                    if (friend.section === FriendListItemSection.Friends) {
                        return (
                            <FriendLink className="mb-2" key={friend.profile.id} profile={friend.profile} >
                                <a className="icon-btn" onClick={() =>
                                    showConfirmationModal(modal, "Remove friend", "Remove", <>{"Are you sure you want to remove " + friend.profile.nickname + " from your friends?"}</>, () => handleRemoveFriend(friend.profile.id))
                                }>
                                    <img src="/images/remove.png" alt="Remove" width="32px" />
                                </a>
                            </FriendLink>
                        );
                    }
                })}

                <br />

                <div className="text-center">
                    <button className="btn" style={{ width: "100%" }} onClick={() => setShowAddFriend(true)}>Add friend</button>
                </div>
            </Box>

            <Box title="My inventory">
                <div className="inventory-grid mb-2">
                    {inventory.loading && <LoadingIndicator />}
                    {!inventory.loading && !isNullOrEmpty(inventory.error) && <ErrorIndicator errorText={inventory.error} />}
                    {inventory.inventoryItems?.map((value) => <InventoryItemComponent key={value.itemData.id} inventoryItem={value} />)}
                </div>
                {auth.myProfile?.accessLevel != undefined && auth.myProfile?.accessLevel >= AccessLevel.Moder &&
                    <div className="text-center pt-3">
                        <Link className="btn" to={"/adm/inventory-management/" + auth.myProfile.id} style={{ width: "100%" }} >{"Change my inventory"}</Link>
                    </div>
                }
            </Box>
        </>
    )
}