import React from 'react';
import { ProfileBadge } from '../models/Profile';
import { CircularAvatar } from './CircularAvatar';

interface Props {
    profile: ProfileBadge,
    children: React.ReactNode,
    className?: string,
}

export function FriendLink(props: Props) {
    const className = "row g-0 " + props.className;

    return (
        <div className={className} >
            <div className="col-auto me-2">
                <CircularAvatar profile={props.profile} />
            </div>
            <div className="col text-truncate">{props.profile?.nickname}</div>
            <div className="col-auto d-flex justify-content-end">
                {props.children}
            </div>
        </div>
    );
}