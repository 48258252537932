import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../App";
import { ItemData } from "../models/ContentSystem";
import { AxiosErrorToString } from "../utils/AxiosUtils";

export function useItems() {
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>();
    const [error, setError] = useState<string>();
    const [items, setItems] = useState<ItemData[]>();

    async function fetch() {
        if (!auth) return;

        setLoading(true);

        const api = await auth.getApi();

        api.get<ItemData[]>("items/GetItems")
            .then((response) => {
                setLoading(false);
                setItems(response.data);
            }).catch((error: AxiosError) => {
                setLoading(false);
                setError(AxiosErrorToString(error));
            });
    }

    useEffect(() => {
        fetch();
    }, []);

    return { loading, error, items };
}