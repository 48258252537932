import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { start } from "repl";
import { AuthContext } from "../App";
import { InventoryItem } from "../models/ContentSystem";
import { Log } from "../models/Log";
import { AxiosErrorToString } from "../utils/AxiosUtils";
import moment from 'moment';


export function useLogs(startDate: moment.Moment, endDate: moment.Moment) {
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>();
    const [error, setError] = useState<string>();
    const [logs, setLogs] = useState<Log[]>([]);

    async function forseUpdate() {
        await fetch();
    }

    async function fetch() {
        if (!auth) return;

        setLoading(true);

        const api = await auth.getApi();

        api.get<Log[]>("logs/GetLogsByPeriod", { params: { startDate: startDate.toDate(), endDate: endDate.toDate() } })
            .then((response) => {
                setLoading(false);
                setLogs(response.data);
            }).catch((error: AxiosError) => {
                setLoading(false);
                setError(AxiosErrorToString(error));
            });
    }

    useEffect(() => {
        fetch();
    }, [startDate, endDate]);

    return { loading, error, logs, forseUpdate };
}