import React, { useContext } from 'react';
import { API_URL } from '../Global';
import config from '../config.json';
import { AuthContext } from '../App';

export function GoogleLoginButton() {
    const auth = useContext(AuthContext);

    return (
        <button className="continue-with-google scale-on-hover" onClick={auth?.openGoogleLogin}>
            <img src="../images/google.png" height="26" alt="Google" />
            &nbsp;
            Continue with Google
        </button>
    );
}