import React, { useState } from 'react';
import { CloseButton, Modal } from 'react-bootstrap';

export interface UniversalModal {
    isShow: boolean,
    show: (content: JSX.Element) => void,
    hide: () => void,
    componentProps: Props,
}

interface Props {
    show: boolean,
    setShow: React.Dispatch<React.SetStateAction<boolean>>,
    children?: JSX.Element,
}

export function useUniversalModal(): UniversalModal {
    const [show, setShow] = useState(false);
    const [content, setContent] = useState<JSX.Element>();

    const um: UniversalModal = {
        isShow: show,
        show: (content: JSX.Element) => {
            setContent(content)
            setShow(true)
        },
        hide: () => setShow(false),
        componentProps: {
            show,
            setShow,
            children: content,
        },
    }

    return um;
}

export function UniversalModalComponent(props: Props) {
    return (
        <Modal show={props.show} onShow={() => props.setShow(true)} onHide={() => props.setShow(false)} centered contentClassName="modal-dark" data-bs-theme="dark" fullscreen="sm-down">
            <Modal.Body>
                <div className="forgot-password-text">  <CloseButton className="float-right btn-close-white" onClick={() => props.setShow(false)} /></div>
                <div className="row align-items-center" style={{ minHeight: "85%" }}>
                    <div>
                        {props.children}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}