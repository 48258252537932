import { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { LoadingIndicator } from '../components/LoadingIndicator';
import { AxiosErrorToString } from '../utils/AxiosUtils';
import { useNavigate } from "react-router-dom";
import { useLoadError } from '../hooks/useLoadError';
import { Box } from '../components/Box';
import { ErrorIndicator } from '../components/ErrorIndicator';
import { AuthContext } from '../App';
import { AuthData } from '../models/AuthData';

interface Params {
    backToGame: boolean;
}

export function GoogleCallbackPage({ backToGame }: Params) {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const [ready, setReady] = useState<boolean>();
    const loadError = useLoadError();

    // ������ ���������� URL � ��������� ��� �����������
    const code = new URLSearchParams(window.location.search).get('code');
    const tempLoginToken = new URLSearchParams(window.location.search).get('state');

    const googleLogin = async () => {
        if (!auth) return;

        loadError.setLoading(true);

        const api = await auth.getApi();

        let currentUrl = window.location.origin + window.location.pathname;

        api.get<AuthData>("authorization/GoogleLogin", { params: { code, redirectUri: currentUrl, tempLoginToken } }).then(response => {
            auth.externalLogin(response.data)
            setReady(true);
        }).catch(e => {
            loadError.setLoading(false);
            loadError.setError(AxiosErrorToString(e as AxiosError));
        });
    }

    useEffect(() => {
        //if (auth?.authenticated) {
        //    setReady(true);
        //}

        googleLogin();
    }, [/*auth?.authenticated*/]);

    if (ready) {
        if (backToGame) {
            return (
                <Box title="You are successfully logged in" colorClass="bg-green">
                    You can return to the game
                </Box>
            );
        } else {
            navigate("/");
            return (
                <Box title="You are successfully logged in" colorClass="bg-green">
                    ...
                </Box>
            );
        }
    }

    return (
        <Box title="Google Login">
            {loadError.loading && <LoadingIndicator />}
            {loadError.error && <ErrorIndicator errorText={loadError.error} />}
        </Box>
    );
}