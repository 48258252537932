import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ChatMessage } from '../../models/Chat';
import { getItemImageUrl, isNullOrEmpty } from '../../utils/commonUtils';
import { format, isToday } from 'date-fns';
import { CircularAvatar } from '../CircularAvatar';
import { AuthContext } from '../../App';
import { AccessLevel } from '../../models/Profile';


interface Props {
    messages: ChatMessage[],
    onRemoveMessage?: (message: ChatMessage) => void,
}

const userLanguage: string = navigator.language;
const currentDate: Date = new Date();

const timeOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
};

function convertUTCDateToLocalDate(dateStr: string) {
    if (dateStr.endsWith("Z")) {
        return new Date(dateStr);
    }

    const date = new Date(dateStr);

    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
}

export function ChatMessageComponent({ messages, onRemoveMessage }: Props) {
    const auth = useContext(AuthContext);

    const date = convertUTCDateToLocalDate(messages[0].dateUtc);

    const isTodayDate = isToday(date);

    let formattedDate: string = '';

    if (isTodayDate) {
        formattedDate = format(date, 'HH:mm');
    } else {
        formattedDate = format(date, 'HH:mm, dd.MM.yyyy');
    }

    const rm = onRemoveMessage !== undefined;
    const accessLevel = auth?.myProfile?.accessLevel ?? AccessLevel.Player;

  return (
      <>
          <div className="row g-0 mb-1">
              <div className="col-auto">
                  <CircularAvatar profile={messages[0].profile} />
              </div>
              <div className="col ms-2">
                  <p className="small mb-1">{messages[0].profile.nickname} &nbsp; <span className="text-muted">{formattedDate}</span></p>
                  <div className="small p-2 rounded-2 mb-1" style={{ backgroundColor: "#FFFFFF07" }}>
                      {messages.map((message) =>
                          <div key={message.id}>
                              {message.text}
                              {rm && accessLevel >= AccessLevel.Moder && <button className="btn-close btn-close-white float-end" style={{ width: "6px", height: "6px" }} onClick={() => onRemoveMessage(message)} />}
                          </div>
                      )}
                  </div>
              </div>
          </div>
      </>
  );
}

export function MyChatMessageComponent({ messages, onRemoveMessage }: Props) {
    const date = convertUTCDateToLocalDate(messages[0].dateUtc);

    const isTodayDate = isToday(date);

    let formattedDate: string = '';

    if (isTodayDate) {
        formattedDate = format(date, 'HH:mm');
    } else {
        formattedDate = format(date, 'HH:mm, dd.MM.yyyy');
    }

    const rm = onRemoveMessage !== undefined;

    return (
        <>
            <div className="row g-0 mb-1">
                <div className="col-auto">
                    <CircularAvatar profile={messages[0].profile} />
                </div>
                <div className="col ms-2">
                    <p className="small mb-1">{messages[0].profile.nickname} &nbsp; <span className="text-muted">{formattedDate}</span></p>
                    
                    <div className="small p-2 rounded-2 mb-1" style={{ backgroundColor: "#00FF0409" }}>
                        {messages.map((message) =>
                            <>
                                <div key={message.id}>
                                    {message.text}
                                    {rm && <button className="btn-close btn-close-white float-end" style={{ width: "6px", height: "6px" }} onClick={() => onRemoveMessage(message)} />} 
                                </div>
                            </>
                          
                        )}
                    </div>
                </div>
                
            </div>
        </>
    );
}