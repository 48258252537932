import React, { useState, useEffect, useContext, useRef } from "react";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { ErrorIndicator } from "../components/ErrorIndicator";
import { ChatMessageComponent, MyChatMessageComponent } from "../components/chat/ChatMessageComponent";
import { Chat, ChatMessage } from "../models/Chat";
import { useChatList } from "../hooks/useChatList";
import { ChatListItemComponent } from "../components/chat/ChatListItemComponent";
import { ChatState, useChat } from "../hooks/useChat";
import { AuthContext, UniversalModalContext } from "../App";
import { GetChatMetadata } from "../utils/chatUtils";
import useFormField from "../hooks/useFormField";
import { Container, Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { showConfirmationModal } from "../components/ConfirmationModal";


export function ChatPage() {
    const auth = useContext(AuthContext);
    const chatList = useChatList();
    const chat = useChat();
    const [showChatList, setShowChatList] = useState(false);
    const messageField = useFormField();
    const [selectedChat, setSelectedChat] = useState<Chat>();
    let allChatsAreRead = true;
    if (chatList.chatList !== undefined) {
        for (var i = 0; i < chatList.chatList.length; i++) {
            if (!chatList.chatList[i].isRead) {
                allChatsAreRead = false;
                break;
            }
        }
    }

    const [isTabActive, setIsTabActive] = useState(true);
    const NotificationSound = require("../notification-sound.mp3");
    const audioPlayer = useRef<HTMLAudioElement>(null);

    // Other
    const modal = useContext(UniversalModalContext);

    function playAudio() {
        if (audioPlayer.current !== null) audioPlayer.current.play();
    }

    useEffect(() => {
        scrollDown();
        if (!isTabActive) playAudio();
    }, [chat.messagesGrouped]);

    useEffect(() => {
        if (chatList.chatList !== undefined && chatList.chatList.length > 0 && selectedChat === undefined) {
            selectChat(chatList.chatList[0]);
        }
    }, [chatList.chatList]);

    useEffect(() => {
        chatList.setChatReadState(chat.notifiedChatId, false);
        chat.setNotifiedChatId(undefined);
        if (!isTabActive) playAudio();
    }, [chat.notifiedChatId]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsTabActive(!document.hidden);
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    function scrollDown() {
        const chatContainer = document.getElementById("chatContainer");
        if (chatContainer === null) {
            return;
        }
        chatContainer.scrollTop = chatContainer.scrollHeight - chatContainer.clientHeight;
    }

    const sendMessage = () => {
        if (messageField.value.length === 0) return;
        chat.sendMessage(messageField.value);
        messageField.setValue("");
    };

    const selectChat = (c: Chat) => {
        chat.setSelectedChatId(c.id);
        setSelectedChat(c);
        setShowChatList(false);
        chatList.setChatReadState(c.id, true);
    };

    const onRemoveMessage = (message: ChatMessage) => {
        showConfirmationModal(modal, "Delete message", "Delete",
            <>
                <div className="mb-2">Are you sure you want to delete this message?</div>
                <ChatMessageComponent messages={[message]} />
            </>,
            () => chat.removeMessage(message.id))
    }

    return (
        /* <Box title="Chat">*/
        <Container fluid className="h-100">
            <audio ref={audioPlayer} src={NotificationSound} />
            {chat.state === ChatState.Loading && <LoadingIndicator />}
            {chat.state === ChatState.Error && <ErrorIndicator errorText={chat.error} />}
            {chat.state === ChatState.NotAuthorized && <ErrorIndicator errorText="Not authorized. First, login" />}
            {chat.state === ChatState.Reconnecting && <><ErrorIndicator errorText={chat.error} /> <LoadingIndicator /></>}

            {chat.state === ChatState.Connected &&
                <Row className="g-2">
                    <Col md={4} className={"d-md-block" + (showChatList ? "" : " d-none")}>
                        <div className="card py-3">
                            <div className="row px-2 mb-3">
                                <div className="col">
                                    <span className="fw-bold">Chats</span>
                                </div>
                                <div className="col-auto d-md-none">
                                    <div className="icon-btn" onClick={() => { setShowChatList(false); }}>
                                        <img src="/images/icons/close.png" alt="Close" width="32px" />
                                    </div>
                                </div>
                            </div>
                            <div id="chatsList" className="chat-list-height-control px-2" style={{ overflowX: "hidden", overflowY: "auto" }}>
                                {chatList.chatList?.map(chatInfo =>
                                    <ChatListItemComponent key={chatInfo.id} chat={chatInfo} selected={chatInfo.id === chat.selectedChatId} onClick={selectChat} />
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col className={"" + (!showChatList ? "" : " d-none")}>
                        <div className="card py-3"  >
                            <div className="row px-2 mb-3">
                                <div className="col">
                                    <img src={GetChatMetadata(selectedChat).chatIconUrl} className="rounded-circle me-2" height="28" alt="Chat Icon" />
                                    <span className="fw-bold">{GetChatMetadata(selectedChat).chatName}</span>
                                </div>
                                <div className="col-auto d-md-none">
                                    <div className="icon-btn img-container" onClick={() => { setShowChatList(true); }}>
                                        <img src="/images/icons/menu.png" alt="Chats" width="32px" />
                                        {!allChatsAreRead && <img src="/images/redcircle.png" className="" style={{ position: "absolute", top: "1px", right: "-1px" }} height="12" alt="Notification" />}
                                    </div>
                                </div>
                            </div>

                            {/* MESSAGES */}
                            <div id="chatContainer" className="chat-height-control overflow-auto px-2">
                                {chat.messagesGrouped.length === 0 ? (
                                    <p className="text-secondary">Chat is empty. Be the first to write a message!</p>
                                ) : (
                                    chat.messagesGrouped.map((messageGroup) => {
                                        if (messageGroup[0].profile.id === auth?.myProfile?.id) {
                                            return (
                                                <MyChatMessageComponent key={messageGroup[0].id} messages={messageGroup} onRemoveMessage={onRemoveMessage} />
                                            );
                                        } else {
                                            return (
                                                <ChatMessageComponent key={messageGroup[0].id} messages={messageGroup} onRemoveMessage={onRemoveMessage} />
                                            );
                                        }
                                    })
                                )}
                            </div>

                            {/* MESSAGE INPUT */}
                            <div className="input-group mt-4 px-3">
                                <input className="form-control" placeholder="Enter a message" type="text" {...messageField.params} onKeyDown={(event) => { if (event.key === "Enter") sendMessage(); }} />
                                <button onClick={sendMessage} className="btn btn-primary scale-on-hover" disabled={messageField.value.length === 0}><img src="/images/send.png" alt="Send" width="20px" /></button>
                            </div>
                        </div>
                    </Col>
                </Row>
            }
        </Container>
    );

    return (
        <div>
            <div className="d-flex">
                {/* ����������� ������ ����� */}
                <div className={"flex-grow-1 d-md-block me-md-3 chat-list-md-max-width" + (showChatList ? "" : " d-none")} >
                    <div className="row d-flex justify-content-center">
                        <div className="card py-3">
                            <div className="row px-2 mb-3">
                                <div className="col">
                                    <span className="fw-bold">Chats</span>
                                </div>
                                <div className="col-auto d-md-none">
                                    <div className="icon-btn" onClick={() => { setShowChatList(false); }}>
                                        <img src="/images/icons/close.png" alt="Close" width="32px" />
                                    </div>
                                </div>
                            </div>
                            <div id="chatsList" className="px-2" style={{ height: "516px", minWidth: "200px", overflowX: "hidden", overflowY: "auto" }}>
                                {chatList.chatList?.map(chatInfo =>
                                    <ChatListItemComponent key={chatInfo.id} chat={chatInfo} selected={chatInfo.id === chat.selectedChatId} onClick={selectChat} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* ����������� ���������� ���� */}
                <div className={"flex-grow-1 d-md-block ms-md-3" + (!showChatList ? "" : " d-none")}>
                    <div className="row d-flex justify-content-center">
                        <div className="card py-3"  >
                            <div className="row px-2 mb-3">
                                <div className="col">
                                    <img src={GetChatMetadata(selectedChat).chatIconUrl} className="rounded-circle me-2" height="28" alt="Chat Icon" />
                                    <span className="fw-bold">{GetChatMetadata(selectedChat).chatName}</span>
                                </div>
                                <div className="col-auto d-md-none">
                                    <div className="icon-btn img-container" onClick={() => { setShowChatList(true); }}>
                                        <img src="/images/icons/menu.png" alt="Chats" width="32px" />
                                        {!allChatsAreRead && <img src="/images/redcircle.png" className="" style={{ position: "absolute", top: "1px", right: "-1px" }} height="12" alt="Notification" />}
                                    </div>
                                </div>
                            </div>

                            {/* MESSAGES */}
                            <div id="chatContainer" className="overflow-auto px-2" style={{ height: "450px" }}>
                                {chat.messagesGrouped.length === 0 ? (
                                    <p className="text-secondary">Chat is empty. Be the first to write a message!</p>
                                ) : (
                                    chat.messagesGrouped.map((messageGroup) => {
                                        if (messageGroup[0].profile.id === auth?.myProfile?.id) {
                                            return (
                                                <MyChatMessageComponent key={messageGroup[0].id} messages={messageGroup} onRemoveMessage={onRemoveMessage} />
                                            );
                                        } else {
                                            return (
                                                <ChatMessageComponent key={messageGroup[0].id} messages={messageGroup} onRemoveMessage={onRemoveMessage} />
                                            );
                                        }
                                    })
                                )}
                            </div>

                            {/* MESSAGE INPUT */}
                            <div className="input-group mt-4 px-3">
                                <input className="form-control" placeholder="Enter a message" type="text" {...messageField.params} onKeyDown={(event) => { if (event.key === "Enter") sendMessage(); }} />
                                <button onClick={sendMessage} className="btn btn-primary scale-on-hover" disabled={messageField.value.length === 0}><img src="/images/send.png" alt="Send" width="20px" /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
}

