import { useContext, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { AuthContext, NotificationsContext, SystemContext } from "../App";
import { ChatMessageComponent } from "../components/chat/ChatMessageComponent";
import { FriendLink } from "../components/FriendLink";
import { getChatMessage, getFriendship } from "../api/fetchFromWebApi";
import { FriendListItemSection } from "../models/FriendListItem";
import { FriendChatMessageNotification, FriendRequestNotification, NotificationType, PlayerNotification, TextNotification, TextNotificationType } from "../models/Notification";

export function PlayerNotificationsHandleComponent() {
    const auth = useContext(AuthContext);
    const systemContext = useContext(SystemContext);
    const notificationsContext = useContext(NotificationsContext);
    const NotificationSound = require("../notification-sound.mp3");
    const audioPlayer = useRef<HTMLAudioElement>(null);

    function playAudio() {
        if (audioPlayer.current !== null) audioPlayer.current.play();
    }

    useEffect(() => {
        if (systemContext?.notificationQueue !== undefined && systemContext?.notificationQueue.length > 0) {
            // ��������� �����������
            const nextNotification = systemContext.notificationQueue[0];
            handleNotification(nextNotification);
        }
    }, [systemContext?.notificationQueue]);



    const handleNotification = async (notification: PlayerNotification) => {
        if (!auth) return;
        console.log("Handle new notification: " + notification.data);

        if (notification.type == NotificationType.Text) {
            const n: TextNotification = JSON.parse(notification.data);
            if (n.ModalMode) {

            } else {
                notificationsContext?.addNotification({
                    type: n.Type === TextNotificationType.Info ? 'info' : n.Type === TextNotificationType.Warn ? 'warn' : 'error',
                    content: (
                        <>
                            {n.Text}
                        </>
                    ),
                    lifetimeInSeconds: n.LifetimeInSeconds,
                });
            }
        }

        else if (notification.type == NotificationType.FriendChatMessage) {
            const n: FriendChatMessageNotification = JSON.parse(notification.data);
            const chatMessage = await getChatMessage(n.ChatMessageId, auth);
            if (chatMessage) {
                playAudio();

                notificationsContext?.addNotification({
                    type: 'info',
                    content: (
                            <ChatMessageComponent messages={[chatMessage]} />
                    ),
                });
            }
        }

        else if (notification.type == NotificationType.FriendRequest) {
            const n: FriendRequestNotification = JSON.parse(notification.data);
            const friendship = await getFriendship(n.FriendId, auth);
            if (friendship && friendship.section === FriendListItemSection.ReceivedRequests) {
                playAudio();

                notificationsContext?.addNotification({
                    type: 'info',
                    content: (
                        <>
                            <p>New friendship request from:</p>
                            <FriendLink profile={friendship.profile}> </FriendLink>
                        </>
                    ),
                });
            }
        }

        else {
            alert("Notification: " + notification.data);
        }

        // �������� ������������� ����������� �� �������
        systemContext?.setNotificationQueue(prev => prev.slice(1));
    }



    return (<audio ref={audioPlayer} src={NotificationSound} />);
}