import React from 'react';


interface Params {
    errorText: string | undefined
}


export function ErrorIndicator(params: Params) {
  return (
      <>
          <p className="text-danger">{params.errorText}</p>
      </>
  );
}

//<p className="text-danger font-weight-bold">Error:</p>