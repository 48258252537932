import { useContext, useState } from 'react';
import { Link, Outlet } from 'react-router-dom'
import { LoginModal } from './LoginModal';
import { AuthContext, SystemContext, UniversalModalContext } from '../App';
import { SystemConnectionState } from '../solutions/systemConnection';
import { Button, Col, Container, Nav, Navbar, NavDropdown, Offcanvas, Row } from 'react-bootstrap';
import { BoxMini } from './Box';
import { BigProfileViewer } from './BigProfileViewer';
import { AccessLevel } from '../models/Profile';
import { AdminPage } from './AdminPage';

export function Page() {
    const auth = useContext(AuthContext);
    const systemContext = useContext(SystemContext);
    const modal = useContext(UniversalModalContext);

    const [menuShow, setMenuShow] = useState(false);

    const hideMenu = () => setMenuShow(false);
    const showMenu = () => setMenuShow(true);

    const showLoginModal = () => {
        modal?.show(<LoginModal />);
    }

    return (
        <>
            <Container data-bs-theme="dark">
                {/* HEADER */}
                <Navbar key="menu" expand={false} fixed="top">
                    <Container>
                        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-xl-between py-3">
                            <Link className="" to="/">
                                <img src="/images/mainlogo.png" alt="Tanks Defense" width="200" />
                            </Link>
                        </div>

                        <Navbar.Toggle aria-controls="menu" onClick={showMenu} />
                    </Container>
                </Navbar>

                {/* MENU */}
                <Offcanvas show={menuShow} onShow={showMenu} onHide={hideMenu} placement="end" id="menu" aria-labelledby="menu" data-bs-theme="dark">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            Menu
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="content-wraper">
                        {auth?.authenticated &&
                            <>
                                {systemContext?.connectionState === SystemConnectionState.Connected &&
                                    <div className="profile-tag w-100 mb-2" style={{ backgroundColor: "#12612F" }}>
                                        <img src="/images/greencircle.png" className="mb-1 me-2" height="12" alt="Notification" />
                                        Current Online: {systemContext.currentOnline}
                                    </div>
                                }

                                <BoxMini colorClass="bg-blue">
                                    <BigProfileViewer profileBadge={auth.myProfile} avatarSize={100} />
                                    <Row className="g-2">
                                    <Col>
                                        <Button variant="danger" onClick={auth.logout} className="w-100 mt-2">Logout</Button>
                                    </Col>
                                    <Col>
                                        <Link className="btn w-100 mt-2" to={"player/" + auth.myProfile?.id} onClick={hideMenu}>My Profile</Link>
                                    </Col>
                                    </Row>
                                </BoxMini>

                                <Link to="chat" className="btn mt-5" aria-controls="menu" onClick={hideMenu}>Chat</Link>
                                <a href="https://play.google.com/store/apps/details?id=com.GreenBoxstudio.TanksDefence" className="btn btn-primary mt-2">Play Tanks Defense</a>

                                {auth.myProfile?.accessLevel != undefined && auth.myProfile?.accessLevel >= AccessLevel.Moder &&
                                <BoxMini colorClass="bg-red mt-5">
                                    <div className="d-flex align-items-center justify-content-center"> <img className="center-block" src="/images/icons/adm.png" alt="Discord" width="32px" /></div>
                                   
                                    <Link className="btn w-100 mt-2" to={"adm/allItems"} onClick={hideMenu}>All Game Items</Link>
                                    <Link className="btn w-100 mt-2" to={"adm/viewLogs"} onClick={hideMenu}>Server Logs</Link>
                                </BoxMini>
                                }
                            </>
                            ||
                            <>
                                <a href="https://play.google.com/store/apps/details?id=com.GreenBoxstudio.TanksDefence" className="btn btn-primary">Play Tanks Defense</a>
                                <Button variant="primary" onClick={showLoginModal} className="mt-2">Login</Button>
                            </>

                        }

                       

                        <div className="footer-wraper">
                            <div className="d-flex align-items-center justify-content-center">
                                <Link to="/" className="me-2"> <img src="/images/TanksDefenseWebIcon.png" alt="Tanks Defense" width="32"></img> </Link>
                                <span className="text-muted">
                                    Green Box Team Project
                                </span>
                            </div>
                            <div className="text-center text-muted mt-2">
                                <Link className="link-secondary" to="/privacy-policy" onClick={hideMenu}>Privacy Policy</Link>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                <a className="link-secondary" href="mailto:support@tanksdefense.com">support@tanksdefense.com</a>
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                                <a href="https://discord.gg/Z7DzeEb"><img className="icon-btn" src="/images/discord.png" alt="Discord" width="32px"></img></a>
                                <a href="https://vk.com/tanksdefense"><img className="icon-btn ms-3" src="/images/vk.png" alt="VK" width="32px"></img></a>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>

                <div className="content box mb-4" style={{ marginTop: "95px", minHeight: "100%" }}>
                    {/* CONTENT */}
                    <Outlet />
                </div>
            </Container>
        </>
    );
}