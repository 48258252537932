import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../App';
import { Box } from '../components/Box';
import { isPlayerId } from '../utils/commonUtils';
import { MyProfilePage } from './MyProfilePage';
import { OtherProfilePage } from './OtherProfilePage';

export function ProfilePage() {
    const auth = useContext(AuthContext);

    const { id } = useParams();

    if (!isPlayerId(id)) {
        return (
            <Box title="Invalid Player ID" colorClass="bg-red">
                ◉︵◉
            </Box>
        );
    }

    let idNum = Number(id);

    if (auth?.myProfile?.id == id) {
        return (
            <MyProfilePage />
        );
    }
    else {
        return (
            <OtherProfilePage id={idNum} />
        );
    }
}
