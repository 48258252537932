import { Chat, ChatType } from "../models/Chat";
import { getItemImageUrl } from "./commonUtils";

interface ChatMetadata {
    chatIconUrl: string;
    chatName: string;
}

export function GetChatMetadata(chat: Chat | undefined): ChatMetadata {
    if (chat === undefined) {
        return { chatIconUrl: "", chatName: "" };
    }

    let chatIconUrl: string = "/images/icons/global_chat.png";
    let chatName: string = chat.name;

    switch (chat.type) {
        case ChatType.Team:
            chatIconUrl = "/images/icons/team_chat.png";
            break;
        case ChatType.Support:
            chatIconUrl = "/images/icons/support_chat.png";
            break;
        case ChatType.Custom:
            chatIconUrl = "/images/icons/custom_chat.png";
            break;
        case ChatType.Friend:
            chatIconUrl = getItemImageUrl(chat.associatedProfile?.avatarId);
            chatName = chat.associatedProfile?.nickname ?? "";
            break;
        default:
    }

    return { chatIconUrl, chatName };
}