import axios, { AxiosInstance } from 'axios';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../Global';
import { AuthData } from '../models/AuthData';
import { PrivateProfile } from '../models/Profile';
import config from '../config.json';

export interface Auth {
    authenticated: boolean | undefined;
    myProfile?: PrivateProfile;
    getAuthDataFromCookies: () => AuthData | null;
    getAccessToken: () => Promise<string | null>;
    getApi: () => Promise<AxiosInstance>;
    register: (email: string, password: string) => void;
    confirmEmail: (email: string, code: string) => void;
    login: (email: string, password: string) => void;
    openGoogleLogin: () => void;
    externalLogin: (authData: AuthData | undefined) => void;
    logout: () => void;
    refreshMyProfile: () => void;
}

export function useAuth(): Auth {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [authenticated, setAuthenticated] = useState<boolean>();
    const [myProfile, setMyProfile] = useState<PrivateProfile>();


    useEffect(() => {
        const authData = getAuthDataFromCookies();

        if (!authData) {
            setAuthenticated(false);
        } else {
            refreshMyProfile();
            setAuthenticated(true);
        }
    }, [cookies]);


    const apiUnauth: AxiosInstance = axios.create({
        baseURL: API_URL,
    });

    const getAuthDataFromCookies = (): AuthData | null => {
        if (!('AuthData' in cookies)) {
            return null;
        }

        if (!cookies['AuthData']) {
            return null;
        }

        const authData: AuthData = cookies['AuthData'];
        authData.expiration = new Date(authData.expiration ?? "");
        return authData;
    }

    const refreshAuthData = async (): Promise<AuthData | null> => {
        console.log("Token refreshing");

        const authData = getAuthDataFromCookies();

        if (!authData) return null;

        try {
            const response = await apiUnauth.post<AuthData>(
                "authorization/RefreshToken",
                {
                    accessToken: authData.accessToken,
                    refreshToken: authData.refreshToken,
                }
            );

            setCookie("AuthData", response.data);

            return response.data;
        } catch (error) {
            console.error('Token refreshing error: ', error);
            logout();
            return null;
        }
    }

    const getAccessToken = async (): Promise<string | null> => {
        const authData = getAuthDataFromCookies();

        if (!authData) return null;

        if (authData.expiration.getTime() > new Date().getTime()) {
            return authData.accessToken;
        }

        const newAuthData = await refreshAuthData();

        if (!newAuthData) return null;

        return newAuthData.accessToken;
    }

    const getApi = async (): Promise<AxiosInstance> => {
        const accessToken = await getAccessToken();

        if (accessToken) {
            return axios.create({
                baseURL: API_URL,
                headers: { Authorization: `Bearer ${accessToken}` }
            })
        } else {
            return apiUnauth;
        }
    }

    const register = async (email: string, password: string) => {
        await apiUnauth.post("authorization/Register", { email, password });
    }

    const confirmEmail = async (email: string, code: string) => {
        const response = await apiUnauth.post("authorization/ConfirmEmail", { email, code });
        setCookie("AuthData", response.data)
    }

    const login = async (email: string, password: string) => {
        const response = await apiUnauth.get("authorization/Login", { params: { email, password } })
        setCookie("AuthData", response.data)
    }

    const openGoogleLogin = async () => {
        const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
        const clientId = config.GOOGLE_CLIENT_ID;
        let currentDomain = window.location.origin;
        const redirectUri = encodeURIComponent(currentDomain + '/google-callback');
        const authUrl = `${googleAuthUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=openid%20email`;
        window.location.href = authUrl;
    }

    const externalLogin = async (authData: AuthData | undefined) => {
        if (!authData) return;
        setCookie("AuthData", authData);
    }

    const logout = async () => {
        console.log("Logout");
        removeCookie("AuthData");
        navigate("/");
    }

    const refreshMyProfile = async () => {
        const api = await getApi();
        try {
            const response = await api.get<PrivateProfile>("profile/GetPrivateProfile");
            setMyProfile(response.data);
        } catch (error) {
            console.error(error);
        }
    }


    return {
        authenticated,
        myProfile,
        getAuthDataFromCookies,
        getAccessToken,
        getApi,
        register,
        confirmEmail,
        login,
        openGoogleLogin,
        externalLogin,
        logout,
        refreshMyProfile,
    };
}