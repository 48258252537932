import { ProfileBadge } from "./Profile";

export interface Chat {
    id: number;
    type: ChatType;
    name: string;
    lastMessageTime: Date;
    isRead: boolean;
    associatedProfile?: ProfileBadge;
}

export enum ChatType {
    Friend = 0,
    Team = 1,
    Support = 2,
    Custom = 3,

    Global = 10,
}

export interface ChatMessage {
    id: number,
    profile: ProfileBadge,
    text: string,
    dateUtc: string,
}