import { Box } from '../components/Box';
import { Carousel } from 'react-bootstrap';

export function HomePage() {
    return (
        <>
            <div className="content-box-image" />

            <Box title="DEFEND, DEVELOP, ATTACK!" colorClass="bg-blue">
                <p>
                    An alien civilization has attacked Earth! You have been appointed commander of a special military base.
                    Using tanks, turrets, and special abilities, you must destroy waves of approaching enemies and prevent them from destroying the base!
                </p>

                <Carousel className="d-flex justify-content-center">
                    <Carousel.Item>
                        <img src="images/screenshots/1.png" className="d-block w-100" alt="Screenshot 1" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src="images/screenshots/1-1.png" className="d-block w-100" alt="Screenshot 2" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src="images/screenshots/2.png" className="d-block w-100" alt="Screenshot 3" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src="images/screenshots/3.png" className="d-block w-100" alt="Screenshot 4" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src="images/screenshots/4.png" className="d-block w-100" alt="Screenshot 5" />
                    </Carousel.Item>
                </Carousel>
            </Box>

            <Box title="Key features" colorClass="bg-yellow">
                <ul>
                    <li>A large number of combat vehicles at your disposal</li>
                    <li>Multiplayer Battles: Base vs Base</li>
                    <li>A 3-chapter story campaign</li>
                    <li>Ability to improve the appearance of tanks (skins)</li>
                </ul>
            </Box>

            <Box title="Trailer" colorClass="bg-red">
                <div className="text-center">
                    <iframe
                        className="yt-trailer"
                        src="https://www.youtube.com/embed/dv4lfOeJAEo"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>
            </Box>

            <Box title="JOIN THE COMMUNITY" colorClass="bg-pink">
                <p>
                    Join our friendly community! Get the latest news, take part in interesting events, contribute to the development of the game!
                </p>
                <div className="text-center">
                    <a href="https://discord.gg/Z7DzeEb">
                        <img className="icon-btn margin-x" src="images/discord.png" alt="Discord" width="64px" />
                    </a>
                    <a href="https://vk.com/tanksdefense">
                        <img className="icon-btn margin-x" src="images/vk.png" alt="VK" width="64px" />
                    </a>
                </div>
            </Box>
        </>
    )
}