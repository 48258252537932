import { AxiosError } from 'axios';
import { isNullOrEmpty } from './commonUtils';

export function AxiosErrorToString(error: AxiosError) : string {
    console.log("Axios error: " + error.message + "(" + error.response?.data + ")");

    var errorDataStr = "" + error.response?.data;
    //return "Error! Status code: " + error.status + ". Message: " + error.message;
    if (isNullOrEmpty(errorDataStr) || errorDataStr === "undefined") {
        //return "Error! Status code: " + error.status + ". Message: " + error.message;
        return error.message;
    } else {
        return errorDataStr;
    }
}