import { API_URL } from '../Global';

export function isNullOrEmpty(str: string | null | undefined): boolean {
    return str === null || str === undefined || str === '';
}

export function getItemImageUrl(itemId: string | null | undefined) {
    if (isNullOrEmpty(itemId)) {
        return API_URL + "img/items/" + "defaultavatar" + "_item.png";
    }

    return API_URL + "img/items/" + itemId + "_item.png";
}


export function isNumeric(str: string | undefined) {
    return !isNaN(Number(str))
}

export function isPlayerId(str: string | undefined) {
    return str !== undefined && !isNullOrEmpty(str) && isNumeric(str) && parseInt(str) >0
}