import React from 'react';
import { ProfileBadge } from '../models/Profile';
import { getItemImageUrl } from '../utils/commonUtils';


interface Props {
    profileBadge: ProfileBadge | undefined,
    avatarSize?: number,
    children?: React.ReactNode
}


export function BigProfileViewer({ profileBadge, avatarSize, children }: Props) {
    return (
        <div className="row g-0">
            <div className="col-auto me-3">
                <img src={getItemImageUrl(profileBadge?.avatarId)} className="big-avatar" height={avatarSize ?? 128} alt="Avatar" />
            </div>
            <div className="col text-truncate">
                <span className="text-secondary fs-5" style={{ verticalAlign: 'middle' }}>ID:&nbsp;{profileBadge?.id}</span>
                <br />
                <span className="big-nickname">{profileBadge?.nickname}</span>
                <br />
                {children}
            </div>
        </div>
    );
}