import { AxiosError } from "axios";
import React, { useState } from "react";
import { AxiosErrorToString } from "../utils/AxiosUtils";

export interface LoadError {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    error: string | undefined;
    setError: React.Dispatch<React.SetStateAction<string | undefined>>
}

export function useLoadError() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    var le: LoadError = { loading, setLoading, error, setError }

    return le;
}