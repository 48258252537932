import moment from "moment";
import { defaultStaticRanges, DateRangePicker } from "react-date-range"
import { Range, RangeKeyDict } from "react-date-range"
import '../css/date-picker-theme.scss'
import '../css/date-picker.scss';


interface Props {
    editableDateInputs?: boolean;
    onChange?: ((rangesByKey: RangeKeyDict) => void) | undefined;
    ranges?: Range[] | undefined;
    scroll?: ScrollOptions;
    showDateDisplay?: boolean;
    showMonthAndYearPickers?: boolean;
    showPreview?: boolean;
    minDate?: moment.Moment;
    maxDate?: moment.Moment;

}

export function DatePickerRange(props: Props) {
    const {
        editableDateInputs = true,
        onChange,
        ranges,
        scroll,
        showDateDisplay = true,
        showMonthAndYearPickers = true,
        showPreview = true,
        maxDate = moment(),
        minDate = moment('2023-01-01'),
    } = props;


    return (
        <DateRangePicker 
            editableDateInputs={editableDateInputs}
            onChange={onChange}
            ranges={ranges}
            scroll={{ enabled: true }}
            showDateDisplay={showDateDisplay}
            showMonthAndYearPickers={showMonthAndYearPickers}
            showPreview={showPreview}
            staticRanges={defaultStaticRanges}
            minDate={minDate.toDate()}
            maxDate={maxDate.toDate()}
        />
    )
}