import { ProfileBadge } from "./Profile";

export interface FriendListItem
{
    profile: ProfileBadge;
    section: FriendListItemSection;
}

export enum FriendListItemSection {
    ReceivedRequests = 0,
    SentRequests = 1,
    Friends = 2,
}